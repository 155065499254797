<script lang="ts" setup>
import { TaskAuditResource } from '@/types/tasks';
import { formatStampAsHumanReadableDateTime } from '@/util/timeFunctions';
import VTable from '@/components/Tables/VTable.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import DisplayRichText from '@/components/Display/DisplayRichText.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import { nextTick, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { safeHtmlStringify } from '@/util/safe-html-stringify';

type Props = {
  audit: TaskAuditResource;
  withImage?: boolean;
  fullText?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  withImage: false,
  fullText: false,
});

const allowedFields = [
  { id: 'title', name: 'Title', missing: ' - ' },
  { id: 'description', name: 'Description', missing: ' - ' },
  { id: 'due_date', name: 'Due Date', missing: ' - ' },
  { id: 'value', name: 'Value', missing: ' - ' },
];

const modalOpen = ref(false);
const activeFields = ref([]);
const openModal = async () => {
  modalOpen.value = false;
  await nextTick();

  const possibleFields = Object.keys(props.audit.old_values).concat(Object.keys(props.audit.new_values));
  activeFields.value = allowedFields.filter((field) => possibleFields.includes(field.id));

  modalOpen.value = true;
};

const getFieldFromArray = (values, field) => {
  if (values.hasOwnProperty(field.id) && values[field.id]) {
    if (field.hasOwnProperty('notMissing')) {
      return field.notMissing;
    }
    return values[field.id];
  }
  return field.missing;
};
const getNameOfAuditor = () => {
  if (!props.audit.person_type) return null;
  if (props.audit.person_type === 'App\\User') {
    if (props.audit.person_id === usePage().props.auth.user.id) {
      return 'You';
    }
  }
  return props.audit.person.name;
};
</script>

<template>
  <div
    :class="{ 'grid-cols-[25px_auto] ': withImage }"
    class="grid items-center gap-edge-1/2 px-edge"
    @click="openModal">
    <div v-if="withImage">
      <img
        v-if="audit.person"
        :src="audit.person.avatar_url"
        class="rounded"
        alt="User" />
    </div>
    <div
      :class="{ 'text-soft': !fullText }"
      class="text-xs">
      <span class="font-semibold mr-edge-1/4">{{ getNameOfAuditor() }}</span>
      <span>
        {{ audit.title }}
      </span>
      <span
        class="text-xs text-soft ml-edge"
        :title="formatStampAsHumanReadableDateTime(audit.created_at)">
        {{ audit.created_at_human }}
      </span>
    </div>

    <CrudModal
      v-if="modalOpen"
      title="Audit"
      small
      :only-close-button="true"
      @closed="modalOpen = false">
      <div class="main-content">
        <div class="sub-title mb-edge px-edge">
          {{ getNameOfAuditor() }}
          {{ audit.title }}
        </div>
        <div v-if="activeFields.length > 0">
          <VTable edge-to-edge>
            <template #head>
              <VTableRow head>
                <VTableCell>Attribute</VTableCell>
                <VTableCell v-if="['updated', 'deleted'].includes(audit.event)"> Old</VTableCell>
                <VTableCell v-if="['created', 'updated'].includes(audit.event)"> New</VTableCell>
              </VTableRow>
            </template>
            <template #default>
              <VTableRow
                v-for="field in activeFields"
                :key="field.id">
                <VTableCell main-cell>
                  <span class="text-uppercase">{{ field.name }}</span>
                </VTableCell>
                <VTableCell v-if="['updated', 'deleted'].includes(audit.event)">
                  <template v-if="[field.type, audit.display_options].includes('field-rich-text')">
                    <div v-html="safeHtmlStringify(getFieldFromArray(audit.old_values, field))" />
                  </template>
                  <template v-else-if="[field.type, audit.display_options].includes('field-text')">
                    <span
                      v-for="(t, idx) in getFieldFromArray(audit.old_values, field).split('\n')"
                      :key="idx">
                      <DisplayRichText :content="t"></DisplayRichText>
                      <br />
                    </span>
                  </template>
                  <template v-else>
                    {{ getFieldFromArray(audit.old_values, field) }}
                  </template>
                </VTableCell>
                <VTableCell v-if="['created', 'updated'].includes(audit.event)">
                  <template v-if="[field.type, audit.display_options].includes('field-rich-text')">
                    <div v-html="safeHtmlStringify(getFieldFromArray(audit.new_values, field))" />
                  </template>
                  <template v-else-if="[field.type, audit.display_options].includes('field-text')">
                    <span
                      v-for="(t, idx) in getFieldFromArray(audit.new_values, field).split('\n')"
                      :key="idx">
                      <DisplayRichText :content="t"></DisplayRichText>
                      <br />
                    </span>
                  </template>
                  <template v-else>
                    {{ getFieldFromArray(audit.new_values, field) }}
                  </template>
                </VTableCell>
              </VTableRow>
            </template>
          </VTable>
        </div>

        <div class="mt-edge px-edge">
          <div class="text-xs text-soft">
            <i class="fa fa-clock mr-edge-1/4" />
            <span class="mr-edge-1/4 italic">when</span>
            <i class="fa fa-minus fa-sm mr-1" />
            <span class="text-sm italic">{{ audit.created_at }}</span>
          </div>
        </div>
      </div>
    </CrudModal>
  </div>
</template>
