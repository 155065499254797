<script setup lang="ts">
import { allAvailableFieldTypes, fieldClasses, fieldHasOptions, fieldHasTable } from '@/util/fields';
import { z } from 'zod';
import FieldListOptionEditor from '@/components/Fields/FieldListOptionEditor.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import CrudModal from '@/components/Modals/CrudModal.vue';
import { computed, ref } from 'vue';
import { getItemFromArrayBasedOnId, getKey, tooShortOrLong } from '@/util/globals';
import { useToast } from 'vue-toastification';
import FieldTableEditor from '@/components/Fields/FieldTableEditor.vue';

type Props = {
  initField: {
    id: number | null;
    title: string;
    class: string;
    component: string;
    options: string[];
    description: string;
    linebreak_after: boolean;
  } | null;
  fieldTypes?: { title: string; icon: string; component: string }[];
  withClass?: boolean;
  handleOptionsOutside?: boolean;
  withDescription?: boolean;
  withLineBreak?: boolean;
  classList?: { value: string; title: string }[];
};
const props = withDefaults(defineProps<Props>(), {
  classList: () => [...fieldClasses],
  withClass: true,
  withDescription: true,
  handleOptionsOutside: false,
  withLineBreak: true,
  fieldTypes: () => [...allAvailableFieldTypes],
});

const emit = defineEmits<{
  (event: 'closed'): void;
  (event: 'created', arg: FieldType): void;
  (event: 'updated', arg: FieldType): void;
  (event: 'deleted', arg: number): void;
}>();

const fieldSchema = z.object({
  component: z.string(),
  title: z.string().min(2),
  class: z.string(),
  linebreak_after: z.boolean(),
  description: z.string(),
  options: z.array(z.any()).nullable(),
});
type FieldType = z.infer<typeof fieldSchema>;
const field = ref<FieldType>({
  title: props.initField?.title ?? '',
  component: props.initField?.component ?? 'field-text',
  options: props.initField?.options ?? [],
  class: props.initField?.class ?? props.classList[0].value,
  linebreak_after: props.initField?.linebreak_after ?? false,
  description: props.initField?.description ?? '',
});
const canSave = computed(() => fieldSchema.safeParse(field.value).success);
const unsavedOption = ref(false);

const translateFieldTypeToTitle = () => {
  const index = _.findIndex(props.fieldTypes, (f) => f.component === field.value.component);
  if (index > -1) {
    return props.fieldTypes[index].title;
  }
  return '';
};

const title = computed(() => {
  let title = 'Add New ';
  if (props.initField && props.initField?.id) {
    title = 'Update ';
  }
  return title + translateFieldTypeToTitle() + ' field';
});

const addField = (close: () => void) => {
  if (props.initField?.id) return;
  if (tooShortOrLong(field.value?.title, 'title')) return;

  emit('created', {
    title: field.value?.title,
    component: field.value?.component,
    class: field.value?.class,
    description: field.value?.description,
    linebreak_after: field.value?.linebreak_after,
    options: fieldHasOptions(field.value) || fieldHasTable(field.value) ? field.value.options : null,
  });

  close();
};

const updateField = (close: () => void) => {
  if (!props.initField?.id) return;

  emit('updated', {
    id: props.initField.id,
    title: field.value.title,
    component: field.value.component,
    class: field.value.class,
    description: field.value.description,
    linebreak_after: field.value.linebreak_after,
    options: fieldHasOptions(field.value) || fieldHasTable(field.value) ? field.value.options : null,
  });

  close();
};

const deleteField = (close: () => void) => {
  if (!props.initField?.id) return;

  emit('deleted', props.initField.id);

  close();
};

const checkIfSizeShouldBeUpdated = () => {
  if (props.initField?.id) return;
  switch (field.value.component) {
    case 'field-rich-text': {
      if (field.value.class === 'col-md-3') {
        field.value.class = 'col-md-6';
        useToast().info('Updated Width');
      }
      break;
    }
    case 'field-table': {
      if (field.value.class !== 'col-md-12') {
        field.value.class = 'col-md-12';
        useToast().info('Updated Width');
      }
      break;
    }
  }
};
</script>

<template>
  <CrudModal
    :update="getKey(initField, 'id') !== null"
    small
    :title="title"
    :disabled="unsavedOption || !canSave"
    @create="addField"
    @update="updateField"
    @delete="deleteField"
    @closed="$emit('closed')">
    <div class="flex flex-col gap-edge">
      <VSelect
        v-if="!initField?.id"
        v-model="field.component"
        label="Type"
        :options="fieldTypes"
        :icon-left="getItemFromArrayBasedOnId(field.component, fieldTypes, { icon: null }, 'component').icon"
        option-value="title"
        option-key="component"
        @update:model-value="[(unsavedOption = false), checkIfSizeShouldBeUpdated()]" />

      <TextInput
        v-model="field.title"
        label="Title"
        autofocus
        placeholder="Field Title" />
      <VSelect
        v-if="withClass"
        v-model="field.class"
        label="Width"
        :options="classList"
        option-key="value"
        option-value="title" />

      <SettingToggle
        v-if="withLineBreak"
        v-model="field.linebreak_after"
        label="Linebreak After" />
      <TextareaInput
        v-if="withDescription"
        v-model="field.description"
        wrapper-class="col-span-2"
        label="Description"
        autofocus
        placeholder="Field Description" />

      <slot
        v-if="fieldHasOptions(field) && !handleOptionsOutside"
        name="listOptions">
        <FieldListOptionEditor
          v-model:unsaved-option="unsavedOption"
          v-model:options="field.options" />
      </slot>

      <slot
        v-if="fieldHasTable(field)"
        name="tableOptions">
        <FieldTableEditor v-model:options="field.options" />
      </slot>

      <slot name="under" />
    </div>
  </CrudModal>
</template>
