import { SideMenuTab } from '@/types/page-tab';
import { getCurrentContextGroup, getKey } from '@/util/globals';
import { getRoute, openRoute } from '@/util/route';
import { useToast } from 'vue-toastification';

export const clickedTheActionButton = (tab: SideMenuTab | null) => {
  const group = getCurrentContextGroup();

  if (!group) return;
  if (group.superadmin) {
    // if (['group', 'events'].includes(tab.key)) {
    //   openRoute(getRoute('onboarding.plans', group.slug), null, true);
    // } else {
    openRoute(getRoute('groups.administrator', group.slug) + '#subscription/plans', null, true);
    // }
  } else {
    const params = {};
    switch (tab.key) {
      case 'group':
      case 'events': {
        params.module = 'Event Creation';
        params.needed_plan = 'Light';
        break;
      }
      case 'meta-data': {
        params.module = 'Meta Data';
        params.needed_plan = 'Light';
        break;
      }
      case 'check-lists': {
        params.module = 'Check Lists';
        params.needed_plan = 'Professional';
        break;
      }
      case 'invoices': {
        params.module = 'Invoices';
        params.needed_plan = 'Professional';
        break;
      }
      case 'soft-access-lists': {
        params.module = 'Soft Access Lists';
        params.needed_plan = 'Premium';
        break;
      }
      case 'shift-types': {
        params.module = 'Shifts';
        params.needed_plan = 'Premium';
        break;
      }
      case 'event-requests': {
        params.module = 'Event Requests';
        params.needed_plan = 'Professional';
        break;
      }
      case 'email-config': {
        params.module = 'Email Configurations';
        params.needed_plan = 'Premium';
        break;
      }
      case 'num-admins': {
        params.module = 'Number of Admins';
        params.needed_plan = null;
        break;
      }

      default: {
        return;
      }
    }

    axios.post(`/api/groups/${group.id}/request-upgrade`, params);

    useToast().success(`All admins of ${group.name} has been notified about your request.`);
  }
};

export const getLockPageItem = (
  tab: SideMenuTab | object | null,
  extraInfo: object | null
): UnlockFeaturePageResource | null => {
  if (!tab) return null;

  const values: UnlockFeaturePageResource = {
    title: getKey(tab, 'title', null),
    key: getKey(tab, 'key', null),
    kbLink: getKey(tab, 'kbLink', null),
    action: () => {
      clickedTheActionButton(tab);
    },
  };

  switch (values.key) {
    case 'meta-data': {
      return {
        ...values,
        header: 'Share Data easier and faster',
        description:
          '<p>Events and Venues are capturing, sharing and reporting information about events using <strong>Meta Data</strong>. You can get Metadata and unlock the power of intuitive and efficient collection and sharing of events data seamlessly across your teams.&nbsp;</p><p style="margin-top: 20px;"><strong>Metadata is popularly used for:</strong></p><ul><li><p>Audience information</p></li><li><p>Production Specifications&nbsp;</p></li><li><p>Event Report</p></li><li><p>Ticketing Information</p></li></ul>',
      };
    }
    case 'check-lists': {
      return {
        ...values,
        header: 'Elevate your planning process with check lists',
        description:
          '<p>Minimise errors, streamline processes and ensure flawless event execution with our powerful checklist module. This feature empowers you to create and reuse standardised task lists across events, ensuring consistent and efficient execution.<br></p><p style="margin-top: 20px"><strong>Checklists are popularly used for</strong></p><ul><li><p>Technical Setup Routines</p></li><li><p>Stage or Venue Management Routines</p></li><li><p>Administrative Tasks</p></li><li><p>Health and Safety Routines&nbsp;</p></li></ul>',
      };
    }
    case 'invoices':
    case 'invoice-products':
    case 'invoice-categories':
    case 'invoice-templates': {
      return {
        ...values,
        // youtubeLink: 'https://www.youtube.com/embed/mcptjjFUYPc',
        header: 'Effortless invoice creation for your events',
        description:
          '<p>Say goodbye to time-consuming invoice creation and hello to the ease of the invoicing module on Crescat. Manage your invoices, add them to event reports and share them with your collaborators in a breeze with this module.&nbsp;</p><p style="margin-top: 20px"><strong>Invoicing is popularly used for</strong></p><ul><li><p>Keeping track of costs per event&nbsp;</p></li><li><p>Creating quotes for clients</p></li><li><p>Event Billing Process</p></li></ul>',
      };
    }
    case 'events': {
      const reason = getKey(extraInfo, 'reason');
      const createdEvents = getKey(extraInfo, 'createdEvents');
      let description =
        '<p>You can not create New Events on the Free Plan. To start executing your own events with Crescat, please upgrade to a paid subscription. </p>';
      if (reason) {
        switch (reason) {
          case 'create': {
            if (createdEvents) {
              description =
                '<p>You can not create more Events on the Free Plan (all ' +
                createdEvents +
                ' has already been created). To create more events with Crescat, please upgrade to a paid subscription. </p>';
            }
            break;
          }
          case 'duplicate': {
            if (createdEvents) {
              description =
                '<p>You can not duplicate more Events on the Free Plan (all ' +
                createdEvents +
                ' has already been created). To create more events with Crescat, please upgrade to a paid subscription. </p>';
            } else {
              if (createdEvents) {
                description =
                  '<p>You can not duplicate Events on the Free Plan. To start executing your own events with Crescat, please upgrade to a paid subscription. </p>';
              }
            }
            break;
          }
        }
      }
      return {
        ...values,
        header: 'Start managing your events with Crescat',
        description: description,
      };
    }
    case 'group': {
      return {
        ...values,
        header: 'Upgrade to get the most out of Crescat',
        description:
          '<p>You are currently on the Free Plan. To start executing your own events with Crescat, please upgrade to a paid subscription. </p>',
      };
    }
    case 'event-requests': {
      return {
        ...values,
        header: 'Provide a better service for your customers.',
        description:
          '<p>Your customers will love this hassle-free booking experience. Create custom forms for clients to request venue space and book events easily. The result is a streamlined workflow which saves you time and improves your overall planning experience.&nbsp;</p><p style="margin-top: 20px"><strong>Event Requests are commonly used by&nbsp;</strong></p><ul><li><p>Venues for Event Organisers booking their rooms</p></li><li><p>Venues for Internal Booking of Rooms</p></li><li><p>Event Organisers for prospective clients.&nbsp;</p></li></ul>',
      };
    }
    case 'soft-access-lists': {
      return {
        ...values,
        header: 'Custom calendars to share your availability',
        description:
          '<p>Tailor your venue schedules effortlessly with custom calendars, publicly or privately shareable. Streamline event planning by integrating Soft Access Lists with event requests, ensuring availability transparency for hassle-free bookings.</p> <p style="margin-top: 20px"><strong>Soft access lists are commonly used to&nbsp;</strong></p><ul><li><p>Share availability to repeat clients.&nbsp;</p></li><li><p>Share availability with prospective customers</p></li><li><p>Create custom schedule views to stakeholders</p></li></ul>',
      };
    }
    case 'shift-types': {
      return {
        ...values,
        header: 'Effortless manage your Team’s Shifts',
        description:
          '<p>Use on-the-go shift templates to effortlessly add and assign shifts directly to groups, teams, and individual users. Ensure seamless event execution with simplified shift management, create and assign shifts with ease. Notify staff through automated notifications, and track attendance all in one module. \n</p>',
      };
    }
    case 'email-config': {
      return {
        ...values,
        header: 'Ditch Generic Emails, Brand your messages',
        description:
          '<p>Elevate your brand presence by configuring logos, brand colors, and even hiding Crescat branding. Make every interaction uniquely yours, leaving a lasting impression on your clients and collaborators. Ditch generic messages, stand out in the inbox, and make communication your own. </p>',
      };
    }
    case 'tasks': {
      return {
        ...values,
        action: null,
        header: 'Coming soon: Seamless task assignment for efficient event collaboration.',
        description:
          '<p>Simplify event management with the versatile Tasking Module. Create and assign tasks to groups, teams, and members within and beyond your event. Whether collaborating across multiple groups or managing internal tasks, the Tasking Module ensures streamlined coordination for flawless execution.</p><p><strong>The Tasking Module is commonly used to:</strong></p><ul><li><p>Assign tasks to internal and external collaborators during events.</p></li><li><p>Coordinate seamlessly with other groups on festivals and multi-group events.</p></li><li><p>Organise and manage team-specific responsibilities effectively.</p></li></ul>',
      };
    }
    case 'performances': {
      return {
        ...values,
        header: 'From Booking to Curtain Call: A central hub to manage your performers.',
        description:
          "<p>Crescat's Performances module simplifies artist management and booking, allowing you to manage everything from a single, central hub. This powerful tool streamlines workflows, keeps track of crucial details, and empowers you to maintain a clear overview of all your&nbsp; performers.</p><p><strong>The performance module is popularly used for:</strong></p><ul><li><p>Travel and Logistics Management</p></li><li><p>Information Sharing and Collection (Advancing)</p></li><li><p>Production Information Organisation</p></li></ul>",
      };
    }
    case 'calendar-links': {
      return {
        ...values,
        header: 'Always in Sync - Crescat Calendar Links',
        description:
          '<p>Are you juggling multiple calendars? Calendar Links lets you seamlessly sync your Crescat schedules with your other calendar apps – say goodbye to scheduling headaches! Generate a unique link for your Groups, Subgroups, or Teams. Share your availability with collaborators in a flash!</p><p><strong>Calendar Links are commonly used by&nbsp;</strong></p><ul><li><p>Collaborators: Keep your team in the loop and ensure seamless collaboration with shared schedules.</p></li><li><p>Clients: Let clients easily see your availability for hassle-free booking – build trust and save time!</p></li><li><p>Stakeholders: Keep stakeholders informed and involved.</p></li></ul>',
      };
    }
    case 'num-admins': {
      const numAdminUsed = getKey(extraInfo, 'numAdminsUsed');
      const maxNumberOfAdmins = getKey(extraInfo, 'maxNumAdmins');
      return {
        ...values,
        header: 'Admins limits reached ',
        description: `This organisation has assigned ${numAdminUsed}/${maxNumberOfAdmins} as Admins. To assign more users as Admins, upgrade your account.`,
      };
    }
  }
  return null;
};

export interface UnlockFeaturePageResource {
  key: string;
  title: string;
  header: string;
  description?: string;
  youtubeLink?: string | null;
  action?: Function | null;
  kbLink?: string | null;
}
