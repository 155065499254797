<script setup lang="ts">
import { z } from 'zod';
import TemplateCrudModal from '@/components/Modals/TemplateCrudModal.vue';
import { destroyInvoiceBasis, patchInvoiceBasis, postInvoiceBasis } from '@/services/api-invoice-rows';
import { InvoiceBasesResource, InvoiceRowCategoryResource } from '@/types/invoice-row';
import { computed, nextTick, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useDeleteObjectModal } from '@/composables/modals/use-delete-object-modal';
import { useRecurringModal } from '@/composables/modals/use-recurring-modal';
import TextInput from '@/components/Inputs/TextInput.vue';
import SettingToggle from '@/components/Inputs/Components/SettingToggle.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import DisplayModal from '@/components/Modals/DisplayModal.vue';
import InvoiceBase from '@/components/Models/Invoices/InvoiceBase.vue';
import RadioBox from '@/components/Icons/RadioBox.vue';
import { formatQueryString, updateQueryString } from '@/util/query-helpers';
import { tooShortOrLong } from '@/util/globals';
import { invoiceStore } from '@/store/invoice-store';

type Props = {
  model: string;
  modelId: number;
  isTemplate: boolean;
  isRecurring?: boolean;
  initInvoiceBasis: InvoiceBasesResource | null;
  templateGroupId?: number | null;
  categories: InvoiceRowCategoryResource[];
};

const props = withDefaults(defineProps<Props>(), {
  templateGroupId: null,
  isRecurring: false,
});

const emit = defineEmits<{
  'created': [payload: InvoiceBasesResource];
  'updated': [payload: InvoiceBasesResource];
  'deleted': [payload: number];
  'closed': [];
}>();

const modalOpen = ref(true);

const toast = useToast();

const { assertReadyToDeleteModal } = useDeleteObjectModal();
const { recurringModal } = useRecurringModal();
const { fetchInvoice } = invoiceStore();

const fromTemplate = ref(!props.isTemplate && !props.initInvoiceBasis?.id);
const loading = ref(false);

const templates = ref<Omit<InvoiceBasesResource, 'total' | 'users' | 'rows'>[]>([]);

const fetchTemplates = async () => {
  if (props.model !== 'Invite') return;
  if (!props.templateGroupId) return;

  templates.value = await fetchInvoice('Group', props.templateGroupId);
};
fetchTemplates();

const invoiceBasisSchema = z.object({
  title: z.string().min(2).max(255).trim(),
  with_vat: z.boolean(),
  template_id: z.number().nullable(),
});

type InvoiceBasisType = z.infer<typeof invoiceBasisSchema>;

const invoiceBasis = ref<InvoiceBasisType>({
  title: props.initInvoiceBasis?.title || '',
  with_vat: props.initInvoiceBasis?.with_vat || false,
  template_id: null,
});

const canSave = computed(() => {
  return invoiceBasisSchema.safeParse(invoiceBasis.value).success && invoiceBasis.value.title.trim().length > 1;
});

const createInvoiceBasis = async () => {
  if (tooShortOrLong(invoiceBasis.value.title)) return;
  const addToAll = props.isRecurring ? await recurringModal('Invoice', '', '') : false;
  if (addToAll === 'cancel') return;
  loading.value = true;
  const { data } = await postInvoiceBasis(props.model, props.modelId, {
    title: invoiceBasis.value.title,
    with_vat: invoiceBasis.value.with_vat,
    is_global: addToAll === 'all',
    template_id: invoiceBasis.value.template_id,
  });
  updateQueryString(formatQueryString('InvoiceBase', data.id), true);
  await nextTick();
  emit('created', data);
  loading.value = false;
  modalOpen.value = false;
  toast.success('Created');
};

const updateInvoiceBasis = async () => {
  if (!props.initInvoiceBasis?.id) return;

  loading.value = true;
  await patchInvoiceBasis(props.initInvoiceBasis?.id, {
    title: invoiceBasis.value.title,
    with_vat: invoiceBasis.value.with_vat,
  });
  emit('updated', {
    ...props.initInvoiceBasis,
    title: invoiceBasis.value.title,
    with_vat: invoiceBasis.value.with_vat,
  });
  loading.value = false;
  toast.success('Updated');
  modalOpen.value = false;
};

const onDeleteInvoiceBasis = async () => {
  if (!props.initInvoiceBasis?.id) return;

  const deleteIt = await assertReadyToDeleteModal(
    `Delete Invoice Basis ${props.initInvoiceBasis.title}?`,
    'Are you sure that you want to delete this invoice basis?'
  );
  if (!deleteIt) return;
  loading.value = false;
  await destroyInvoiceBasis(props.initInvoiceBasis.id);
  emit('deleted', props.initInvoiceBasis.id);
  toast.success('Invoice Basis was deleted');
  modalOpen.value = false;
};
</script>

<template>
  <TemplateCrudModal
    v-if="modalOpen"
    v-model="fromTemplate"
    :loading="loading"
    :is-template="isTemplate"
    type="Invoice"
    :disabled="!canSave"
    :update="initInvoiceBasis?.id !== null"
    @create="createInvoiceBasis"
    @update="updateInvoiceBasis"
    @delete="onDeleteInvoiceBasis"
    @closed="$emit('closed')">
    <div class="form-layout">
      <div v-if="fromTemplate && !isTemplate && !initInvoiceBasis?.id">
        <h4 class="my-edge-1/4">Select template</h4>
        <div class="max-h-[calc(20vh)] overflow-auto">
          <VTable
            row-size="small"
            :bordered-table="true">
            <VTableRow
              v-for="template in templates"
              :key="template.id"
              :main-row="invoiceBasis.template_id === template.id"
              clickable
              @click="
                [
                  (invoiceBasis.template_id = template.id),
                  (invoiceBasis.title = template.title),
                  (invoiceBasis.with_vat = template.with_vat),
                ]
              ">
              <VTableCell>
                <RadioBox :model-value="invoiceBasis.template_id === template.id" />
                {{ template.title ?? 'Without Template' }}
              </VTableCell>
              <VTableCell style="width: 50px">
                <DisplayModal
                  v-if="template.id"
                  :title="template.title"
                  without-button-text
                  @selected="
                    [
                      (invoiceBasis.template_id = template.id),
                      (invoiceBasis.title = template.title),
                      (invoiceBasis.with_vat = template.with_vat),
                    ]
                  ">
                  <InvoiceBase
                    :invoice-base="template"
                    :can-edit="false"
                    :is-template="false"
                    is-display
                    :categories="categories"
                    :model="model"
                    :model-id="modelId"
                    :template-group-id="templateGroupId" />
                </DisplayModal>
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>
      </div>

      <TextInput
        v-model="invoiceBasis.title"
        required
        label="Title"
        @keydown.enter="initInvoiceBasis?.id === null ? createInvoiceBasis() : updateInvoiceBasis()" />

      <SettingToggle
        v-model="invoiceBasis.with_vat"
        label="Include VAT in this Invoice Base" />
    </div>
  </TemplateCrudModal>
</template>
