<script setup lang="ts">
import { ref, useTemplateRef } from 'vue';
import BaseSlideout from '@/components/Base/BaseSlideout.vue';
import { useInfiniteScroll } from '@vueuse/core';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { usePage } from '@inertiajs/vue3';
import { TaskAuditResource } from '@/types/tasks';
import { formatAppModel } from '@/util/format-app-model';
import { getKey } from '@/util/globals';

const {
  eventId = undefined,
  ownerId,
  ownerModel,
  inviteId = undefined,
} = defineProps<{
  ownerModel: 'Group' | 'User';
  ownerId: number;
  eventId?: number;
  inviteId?: number;
}>();

defineEmits<{
  (e: 'closed'): void;
}>();

const target = useTemplateRef<HTMLDivElement>('target');

const loading = ref(false);
const isOpen = ref(true);

const audits = ref<TaskAuditResource[]>([]);
const page = ref(1);
const totalPages = ref(1);
const totalAudits = ref(null);

const fetching = ref(false);

const getTitle = () => {
  if (inviteId) {
    return 'Private Event Tasks';
  } else if (eventId) {
    return 'Event Tasks';
  }
  switch (ownerModel) {
    case 'Group': {
      return 'Group Tasks';
    }
    default: {
      break;
    }
  }
  return 'Tasks';
};

const loadAudits = () => {
  if (loading.value) return;
  fetching.value = true;
  loading.value = true;
  let params = {
    page: page.value,
  };

  if (inviteId) {
    params.model_type = formatAppModel('Invite');
    params.model_id = inviteId;
    // });
  } else if (eventId) {
    params.model_type = formatAppModel('Event');
    params.model_id = eventId;
  } else if (ownerModel && ownerId) {
    params.model_type = formatAppModel(ownerModel);
    params.model_id = ownerId;
  }
  axios
    .get('/api/task-audits', {
      params: params,
    })
    .then((resp) => {
      if (resp.data.meta.hasOwnProperty('total')) {
        totalAudits.value = resp.data.meta.total;
      }
      if (resp.data.meta.hasOwnProperty('last_page')) {
        totalPages.value = resp.data.meta.last_page;
      } else if (resp.data.data.length > 0) {
        totalPages.value = page.value + 1;
      }
      if (resp.data.data.length) {
        audits.value = audits.value.concat(resp.data.data);
      }

      loading.value = false;
      fetching.value = false;
    })
    .catch((error) => {
      console.error(error);
    });
};

useInfiniteScroll(
  target,
  async () => {
    // load more
    if (page.value < totalPages.value) {
      page.value += 1;
      loadAudits();
    }
  },
  { distance: 10 }
);
loadAudits();
</script>

<template>
  <BaseSlideout
    v-model="isOpen"
    small
    within-same
    :base-z-index="1000"
    @closed="$emit('closed')">
    <template #header>
      <div class="flex flex-col border-b p-edge">
        <div class="flex gap-edge-1/2">
          <IconWithLoading
            icon="fa-history"
            classes="text-3xl"
            :loading="loading"></IconWithLoading>
          <h1>{{ totalAudits }} Records</h1>
        </div>
        <div class="sub-title text-soft">
          {{ getTitle() }}
        </div>
      </div>
    </template>

    <div class="h-full overflow-hidden bg-content-main">
      <div
        v-if="!audits.length"
        class="p-edge">
        <p>You don't have any audits yet</p>
      </div>

      <div
        v-if="audits.length"
        ref="target"
        class="flex h-full flex-col overflow-auto [&>div]:px-edge [&>div]:p-edge-1/2">
        <div
          v-for="audit in audits"
          :key="audit.id"
          class="border-l-2 border-l-transparent group grid grid-cols-[30px_auto] items-center gap-edge-1/2 py-edge-1/4 transition-all">
          <div class="image pull-left align-content-vertically">
            <img
              v-if="audit.person && audit.person.avatar_url"
              :src="audit.person.avatar_url"
              class="img-responsive rounded-full aspect-1" />
            <img
              v-else
              :src="usePage().props.asset_url + 'assets/images/default-avatar.png'"
              class="img-responsive rounded-full aspect-1" />
          </div>
          <div class="overflow-hidden pr-edge-1/4">
            <div class="sub-title">
              {{ audit.person ? audit.person.name : 'Unknown user' }}
              {{
                audit.event === 'updated' && audit.tags && getKey(audit, 'tags', []).length > 0
                  ? audit.tags[0]
                  : audit.event
              }}
              "{{ audit.task.title }}"
            </div>
            <small
              class="mt-edge-1/4 text-soft"
              :title="audit.created_at">
              {{ audit.created_at_human }}
            </small>
          </div>
        </div>

        <div v-if="audits.length && page >= totalPages">
          <span
            class="mt-25"
            @click.prevent>
            All Loaded
          </span>
        </div>
      </div>

      <div v-if="loading">
        <div class="mt-25">
          <h3>
            <i class="fa fa-fw fa-circle-o-notch fa-spin" />
          </h3>
        </div>
      </div>
    </div>
  </BaseSlideout>
</template>
