import { useLocalStorage } from '@vueuse/core';
import { ref } from 'vue';

export const useTheme = (store: boolean = false, storageString: string = 'theme') => {
  const html = document.documentElement;
  const currentThemeData = html.getAttribute('data-theme') as 'light' | 'base';
  // const THEMES = ['light', 'base', 'less', 'red', 'blue', 'tone_brown', 'new'] as const;
  const THEMES = ['light', 'base', 'light_old'] as const;

  const currentTheme = store
    ? useLocalStorage<'light' | 'base'>(storageString, currentThemeData || 'base')
    : ref<'light' | 'base'>(currentThemeData || 'base');

  html.setAttribute('data-theme', currentTheme.value);

  const changeTheme = () => {
    const newTheme = currentTheme.value === 'base' ? 'light' : 'base';
    html.setAttribute('data-theme', newTheme);
    currentTheme.value = newTheme;
  };

  return {
    currentTheme,
    changeTheme,
    themes: THEMES,
  };
};
