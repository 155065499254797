<script lang="ts" setup>
import BaseModal from '@/components/Base/BaseModal.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { computed, ref } from 'vue';
import { safeHtmlStringify } from '@/util/safe-html-stringify';
import { useSmallScreen } from '@/composables/use-small-screen';

type Props = {
  updateButtonString?: string;
  deleteButtonString?: string;
  titleCenter?: boolean;
  closeButtonText?: string;
  contentClasses?: string;
  portalTo?: string;
  usePortal?: boolean;
  hideAllCrudModals?: boolean;
  deleteButton?: boolean;
  modalInModal?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onlyCloseButton?: boolean;
  title?: string;
  titleHighlight?: string | string[];
  update?: boolean;
  createButtonText?: string;
  tabIndex?: number;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  xLarge?: boolean;
  fullWidth?: boolean;
  warningBeforeClose?: boolean;
  shouldTeleport?: boolean;
  withFirstInputFocus?: boolean;
  withoutBackdrop?: boolean;
};

export type Close = () => void;

const props = withDefaults(defineProps<Props>(), {
  updateButtonString: 'Update',
  deleteButtonString: 'Delete',
  closeButtonText: 'Close',
  contentClasses: '',
  portalTo: 'crudModal',
  title: '',
  titleHighlight: '',
  createButtonText: 'Create',
  titleCenter: false,
  usePortal: true,
  hideAllCrudModals: true,
  deleteButton: true,
  modalInModal: false,
  loading: false,
  disabled: false,
  onlyCloseButton: false,
  update: false,
  small: false,
  medium: true,
  large: false,
  xLarge: false,
  fullWidth: false,
  warningBeforeClose: false,
  tabIndex: 10,
  shouldTeleport: true,
  withFirstInputFocus: true,
  withoutBackdrop: false,
});

const emit = defineEmits<{
  (e: 'closed'): void;
  (e: 'disabled-clicked'): void;
  (e: 'create', onClose: Close): void;
  (e: 'update', onClose: Close): void;
  (e: 'delete', onClose: Close): void;
}>();

const { isSmallScreen } = useSmallScreen();

const width = computed(() => {
  if (isSmallScreen.value) return '95vw';
  if (props.fullWidth) return '90vw';
  if (props.small) return '500px';
  if (props.large) return '800px';
  if (props.xLarge) return '1200px';
  return '600px';
});

const create = (close: Close): void => {
  emit('create', close);
};

const updateClick = (close: Close): void => {
  emit('update', close);
};

const deleteClick = (close: Close): void => {
  emit('delete', close);
};

const onSubmit = (close: Close): void => {
  if (props.disabled || props.loading) return;

  if (props.update) {
    updateClick(close);
  } else {
    create(close);
  }
};

const modal = ref(null);

defineExpose({
  modal,
});

const onClickOutside = async (close: () => void) => {
  if (props.warningBeforeClose) {
    const yes = await useCertaintyModal().assertCertain('Close modal', 'Are you sure you want to close this modal?');
    if (yes) {
      close();
    }
  }
};

const isHighlightWord = (word: string) => {
  if (props.titleHighlight) {
    switch (typeof props.titleHighlight) {
      case 'object': {
        return props.titleHighlight.includes(word);
      }
      case 'string': {
        return word === props.titleHighlight;
      }
      default: {
        break;
      }
    }
  }
  return false;
};

const getTitle = () => {
  let title = props.title;

  const replaceWord = (string: string, word: string) => {
    return string.replace(word, `<span class="mx-edge-1/4">"${word}"</span>`);
  };

  if (props.titleHighlight) {
    switch (typeof props.titleHighlight) {
      case 'object': {
        for (let i = 0; i < props.titleHighlight.length; i++) {
          title = replaceWord(title, props.titleHighlight[i]);
        }
        break;
      }
      case 'string': {
        title = replaceWord(title, props.titleHighlight);
        break;
      }
      default: {
        break;
      }
    }
  }
  return title;
};

const contentSlot = ref<HTMLInputElement | null>(null);
const checkIfSomethingShouldBeActive = () => {
  if (!props.withFirstInputFocus) return;
  try {
    const focusElements = ['INPUT', 'TEXTAREA', 'SELECT'];
    let activeElement = document.activeElement;
    if (focusElements.includes(activeElement.tagName)) return;
    const firstInput = contentSlot.value?.querySelector('input');
    if (firstInput) {
      firstInput.focus();
    }
  } catch (e) {}
};
setTimeout(() => {
  checkIfSomethingShouldBeActive();
}, 400);

defineSlots<{
  default?: (props: { close: () => void }) => never;
  heading?: (props: { close: () => void }) => never;
  'heading-text'?: () => never;
  'footer'?: (props: { close: () => void }) => never;
  'area-in-footer-for-buttons'?: () => never;
  'create-button-area'?: (props: { close: () => void }) => never;
  'update-button-area'?: (props: { close: () => void }) => never;
}>();
</script>

<template>
  <BaseModal
    ref="modal"
    v-slot="{ close }"
    :click-to-close="!warningBeforeClose"
    :should-teleport="shouldTeleport"
    :width="width"
    :bg-overlay-backdrop="withoutBackdrop ? 'bg-transparent' : undefined"
    @closed="$emit('closed')"
    @click-outside="onClickOutside">
    <div>
      <slot
        name="heading"
        :close="close">
        <div
          class="h-[64px] bg-[--color-background-modal-header] px-edge flex items-center justify-between gap-edge-1/4 border-b">
          <h2
            :class="{ 'text-center': titleCenter }"
            :title="safeHtmlStringify(getTitle())"
            class="flex-1 truncate">
            <slot name="heading-text">
              <span v-html="safeHtmlStringify(getTitle())" />
            </slot>
          </h2>
          <div>
            <VButton
              icon="fa-times"
              size="sm"
              @click="close()" />
          </div>
        </div>
      </slot>

      <div class="p-edge max-h-[70vh] md:max-h-[80vh] overflow-auto">
        <div ref="contentSlot">
          <slot :close="close" />
        </div>
      </div>

      <slot
        :close="close"
        name="footer">
        <div
          v-if="!onlyCloseButton"
          class="flex justify-between gap-edge h-[64px] border-t border-transparent items-center px-edge">
          <div>
            <slot name="area-in-footer-for-buttons"></slot>
          </div>
          <div
            v-if="update"
            class="flex gap-edge">
            <slot
              name="update-button-area"
              :close="close">
              <VButton
                v-if="deleteButton"
                :loading="loading"
                :title="deleteButtonString ?? 'Delete'"
                type="warning"
                size="lg"
                @click="deleteClick(close)" />
              <VButton
                :disabled="disabled"
                :loading="loading"
                :title="updateButtonString ?? 'Update'"
                type="primary"
                :emphasized="true"
                size="lg"
                @click="updateClick(close)" />
            </slot>
          </div>

          <slot
            v-if="!update"
            :close="close"
            name="create-button-area">
            <VButton
              v-if="!update"
              :disabled="disabled"
              :loading="loading"
              :emphasized="true"
              size="lg"
              :title="createButtonText ?? 'Create'"
              type="primary"
              @click="create(close)" />
          </slot>
        </div>
      </slot>
    </div>
  </BaseModal>
</template>
