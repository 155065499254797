<script lang="ts" setup>
import type { LabelPlacement } from '@/components/Base/BaseInput.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import WordExchanger from '@/components/Fields/WordExchanger/WordExchanger.vue';
import ButtonGroup from '@/components/Inputs/Components/ButtonGroup.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { type OrderDataListBy, useLocalStoreDataListOptions } from '@/composables/use-local-store-data-list-options';
import { addWordToContentOfField, sanitizeValueForExchangeWords } from '@/util/exchangeFunctions';
import { computed, ref, watch } from 'vue';

type Props = {
  modelValue?: string | null;
  isHidden?: boolean;
  withPen?: boolean;
  actionButtons?: boolean;
  withDeleteAction?: boolean;
  withClearAction?: boolean;
  withSaveAction?: boolean;
  saveActionTitle?: string | null;
  rightIcon?: string | null;
  withClear?: boolean;
  alwaysShowClear?: boolean;
  textWrapperClass?: string | null;
  canEdit?: boolean;
  disabled?: boolean;
  placeholder?: string;
  helperText?: string | null;
  label?: string | null;
  labelTitle?: string | null;
  required?: boolean;
  dataListOptions?: string[];
  exchangeWords?: string[];
  dataListOptionsLocalStorageString?: string | null;
  leftIcon?: string | null;
  errorMessage?: string | null;
  inputClassName?: string | null;
  loading?: boolean;
  labelPlacement?: LabelPlacement;
  setFocus?: boolean;
  minLength?: number;
  orderDataListOptionsBy?: OrderDataListBy;
  square?: boolean;
  forceLowerCase?: boolean;
  inputClass?: string;
};

// create props with default values
const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  isHidden: false,
  withPen: true,
  actionButtons: false,
  withDeleteAction: false,
  withClearAction: true,
  withSaveAction: true,
  saveActionTitle: null,
  rightIcon: null,
  withClear: false,
  alwaysShowClear: false,
  textWrapperClass: null,
  canEdit: true,
  placeholder: '',
  helperText: null,
  label: null,
  labelTitle: null,
  required: false,
  dataListOptions: () => [],
  exchangeWords: () => [],
  dataListOptionsLocalStorageString: null,
  leftIcon: null,
  errorMessage: null,
  inputClassName: null,
  loading: false,
  labelPlacement: 'top',
  setFocus: false,
  disabled: false,
  minLength: 0,
  orderDataListOptionsBy: 'last_use',
  square: false,
  forceLowerCase: false,
  inputClass: undefined,
});

const emits = defineEmits<{
  (event: 'update:modelValue', arg: string | number | null): void;
  (event: 'clickIconRight', args: MouseEvent): void;
  (event: 'clear'): void;
  (event: 'save'): void;
  (event: 'delete'): void;
  (event: 'blur', arg: string | number): void;
  (event: 'keydown.enter', arg: KeyboardEvent): void;
  (event: 'paste', arg: ClipboardEvent): void;
}>();

let allOptions: string[] = [];
let saveActions: ((event: string) => void) | null = null;

if (props.dataListOptionsLocalStorageString) {
  const { options, saveToLocalStorage } = useLocalStoreDataListOptions(
    props.dataListOptionsLocalStorageString,
    props.orderDataListOptionsBy
  );
  allOptions = options.value;
  saveActions = saveToLocalStorage;
}

const inFocus = ref(false);

const activeRightIcon = computed(() => {
  if (!props.isHidden || inFocus.value) {
    return props.rightIcon;
  }
  return null;
});

const wrapper = ref<typeof BaseInput>();

defineExpose({
  wrapper,
});

const allInputClasses = computed(() => {
  const classes = props.actionButtons ? 'border-r-0 rounded-tr-none rounded-br-none' : '';
  return `${props.inputClassName} ${classes}`;
});

const localModelValue = ref(props.modelValue);
watch(
  () => props.modelValue,
  () => {
    localModelValue.value = props.modelValue;
  }
);

const addWordToContent = (word: string) => {
  const content = addWordToContentOfField(word, props.modelValue, wrapper.value ? wrapper.value.input : '');
  emits('update:modelValue', content);
  emits('blur', content);
  localModelValue.value = content;
};

const emitModelValue = (newValue: string | number | null) => {
  const theValue = sanitizeValueForExchangeWords(props.exchangeWords, newValue, localModelValue.value);
  emits('update:modelValue', theValue);
};

const onBlur = (value: string) => {
  if (saveActions && props.dataListOptionsLocalStorageString) {
    saveActions(value);
  }
};

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <div
    :class="[{ 'flex items-end [&>div>div]:w-full ': actionButtons, ' opacity-50': disabled }, textWrapperClass]"
    class="group relative">
    <BaseInput
      ref="wrapper"
      :can-edit="canEdit"
      :data-list-options="allOptions.concat(dataListOptions)"
      :disabled="!canEdit"
      :error-message="errorMessage"
      :helper-text="helperText"
      :square="square"
      :input-class-name="allInputClasses"
      :is-hidden="isHidden"
      :label="label"
      :label-placement="labelPlacement"
      :label-title="labelTitle"
      :left-icon="leftIcon"
      :input-class="inputClass"
      :loading="loading"
      :min-length="minLength"
      :model-value="localModelValue"
      :placeholder="placeholder"
      :required="required"
      :right-icon="activeRightIcon"
      :set-focus="setFocus"
      :with-clear="withClear && !actionButtons"
      :always-show-clear="alwaysShowClear"
      :wrapper-class="actionButtons ? 'flex-1' : null"
      :force-lower-case="forceLowerCase"
      v-bind="$attrs"
      @paste="emits('paste', $event)"
      @blur="[(inFocus = false), $emit('blur', $event), onBlur($event)]"
      @clear="$emit('clear', $event)"
      @focus="inFocus = true"
      @keydown.enter="$emit('keydown.enter', $event)"
      @update:model-value="emitModelValue">
      <template
        v-if="$slots.end"
        #end>
        <slot name="end" />
      </template>
    </BaseInput>
    <ButtonGroup v-if="actionButtons">
      <VButton
        v-if="withSaveAction"
        :title="saveActionTitle"
        icon="fa-save"
        size="xl"
        tool-tip-text="Save"
        type="primary"
        emphasized
        @click="$emit('save', modelValue)" />
      <VButton
        v-if="withDeleteAction"
        icon="fa-trash"
        size="lg"
        tool-tip-text="Delete"
        type="warning"
        @click="$emit('delete')" />
      <VButton
        v-if="withClearAction"
        icon="fa-times"
        size="lg"
        tool-tip-text="Cancel Changes."
        type="pending"
        @click="$emit('clear')" />
    </ButtonGroup>
    <WordExchanger
      v-if="exchangeWords.length > 0"
      :items="exchangeWords"
      @add-word="addWordToContent" />
  </div>
</template>
