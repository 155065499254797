<script lang="ts" setup>
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { computed, ref } from 'vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import { getKey } from '@/util/globals';
import VButton from '@/components/Inputs/VButton.vue';

type OptionType = string[] | object[] | number[];

type Props = {
  options: OptionType;
  overrideWidth?: number | null;
  nullable?: boolean;
  multiple?: boolean;
  groups?: boolean;
  withFiltering?: boolean;
  buttonText?: string;
  selectedText?: string | number | null;
  icon?: string | null;
  toolTipText?: string | null;
};

const props = withDefaults(defineProps<Props>(), {
  overrideWidth: null,
  toolTipText: null,
  icon: null,
  buttonText: '',
  selectedText: 'All',
  multiple: false,
  groups: false,
  withFiltering: false,
});

const open = ref(false);
const searchTerm = ref('');
const filterOptions = computed(() => {
  if (!props.withFiltering) {
    return props.options;
  }
  return props.options.filter((o) => {
    if (getKey(o, 'type') !== null) return searchTerm.value.length === 0 || getKey(o, 'type') === 'header';
    return searchTerm.value.length === 0
      ? true
      : getKey(o, 'title', '').toLowerCase().includes(searchTerm.value.toLowerCase());
  });
});
</script>

<template>
  <div
    class="text"
    :title="toolTipText">
    <VDropdown
      :close-on-click="!multiple"
      :items="filterOptions"
      :have-max-width="false"
      with-arrows-up-and-down
      :highlight-text="searchTerm"
      @dropdown-opened="open = true"
      @dropdown-closed="open = false">
      <template
        v-if="withFiltering"
        #aboveDropdown>
        <TextInput
          v-model="searchTerm"
          placeholder="Search"
          set-focus
          square />
      </template>

      <template #click-area>
        <VButton
          :tool-tip-text="toolTipText"
          :emphasized="false"
          :title="`${buttonText} ${selectedText}`"
          post-icon="fa-chevron-down"
          :icon="icon" />
        <!--        <button-->
        <!--          :title="toolTipText ? toolTipText : buttonText + ' ' + selectedText"-->
        <!--          :style="overrideWidth ? 'max-width: ' + overrideWidth + 'px' : ''"-->
        <!--          class="btn btn-tiny btn-info bg-transparent border-transparent truncate py-[2px] flex items-center gap-1">-->
        <!--          <i-->
        <!--            v-if="icon"-->
        <!--            :class="icon"-->
        <!--            class="fa fa-fw mr-1"></i>-->
        <!--          <span> {{ buttonText }} </span>-->
        <!--          <span class="selected-text-container">-->
        <!--            {{ selectedText }}-->
        <!--          </span>-->
        <!--          <i class="fa fa-fw text-xxs fa-chevron-down ml-edge-1/2"></i>-->
        <!--        </button>-->
      </template>
    </VDropdown>
  </div>
</template>
