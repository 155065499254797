<script setup lang="ts">
import { usePage } from '@inertiajs/vue3';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';
import { getRoute, openRoute } from '@/util/route';
import VList, { Items } from '@/components/VList.vue';
import { useUserStore } from '@/store/UserStore';
import { useTheme } from '@/util/changeTheme';
import VButton from '@/components/Inputs/VButton.vue';

const userStore = useUserStore();

const { themes } = useTheme();

const changeTheme = (theme: (typeof Themes)[0]) => {
  const html = document.documentElement;
  // const currentTheme = html.getAttribute('data-theme');
  const newTheme = theme;
  html.setAttribute('data-theme', newTheme);
  userStore.theme = newTheme;
};

const adminLinks = userStore.admin
  ? [
      { title: 'Dashboard', route: getRoute('admin.dashboard') },
      {
        title: 'Administrator',
        action: () => {
          return (window.location = '/administrator');
        },
      },
      { title: 'Groups', route: getRoute('admin.groups.index') },
      { title: 'Festivals', route: getRoute('admin.festivals.index') },
      { title: 'Events', route: getRoute('admin.events.index') },
      { title: 'User Activity', route: getRoute('admin.userActivity') },
      { title: 'Data Display', route: getRoute('admin.dataDisplay') },
      { title: 'Venues', route: getRoute('admin.venues.index') },
      { title: 'Audit log', route: getRoute('admin.audits') },
      {
        title: 'Event Resource Types',
        route: getRoute('admin.eventResourceTypes'),
      },
      { title: 'Templates', route: getRoute('admin.templates.index') },
      {
        title: 'Push Notifications',
        route: getRoute('admin.pushNotifications.index'),
      },
      { title: 'Reminders', route: getRoute('admin.reminders') },
      { title: 'Caches', route: getRoute('admin.caches.groups') },
      { title: 'Components', route: getRoute('components') },
      { title: 'Design', route: getRoute('admin.design') },
      { title: 'API & Integrations', route: getRoute('profile.api') },
    ]
  : [];

const dropDownList = () => {
  const array = [
    {
      title: 'Profile',
      value: 1,
      preIcon: 'fa-address-card fa-regular',
      action: (closeIt: () => void) => {
        openRoute(getRoute('profile.settings'));
        closeIt();
      },
    },
    {
      title: 'Integrations',
      value: 2,
      preIcon: 'fa-calendar fa-regular',
      action: (closeIt: () => void) => {
        openRoute(getRoute('profile.settings') + '#calendar');
        closeIt();
      },
    },
    {
      title: 'Change email',
      value: 3,
      preIcon: 'fa-envelope fa-regular',
      action: (closeIt: () => void) => {
        openRoute(getRoute('profile.settings') + '#email');
        closeIt();
      },
    },
    {
      title: 'Change password',
      value: 4,
      preIcon: 'fa-key fa-regular',
      action: (closeIt: () => void) => {
        openRoute(getRoute('profile.settings') + '#password');
        closeIt();
      },
    },
    {
      title: 'Manage Affiliations',
      value: 5,
      preIcon: 'fa-network-wired fa-regular',
      action: (closeIt: () => void) => {
        openRoute(getRoute('profile.settings') + '#affiliations');
        closeIt();
      },
    },
    {
      title: 'Notifications',
      value: 6,
      preIcon: 'fa-bell fa-regular',
      action: (closeIt: () => void) => {
        openRoute(getRoute('profile.settings') + '#notification_group');
        closeIt();
      },
    },
  ] as Items;

  if (userStore.intro) {
    array.push({
      title: `Show Tutorial`,
      value: 6,
      preIcon: 'fa-play fa-regular',
      action: (closeIt: () => void) => {
        userStore.intro();
        closeIt();
      },
    });
  }

  array.push({
    title: `Switch to ${userStore.theme === 'light' ? 'Dark' : 'Light'} Mode`,
    value: 7,
    subListLeft: true,
    preIcon: userStore.theme === 'light' ? 'fa-moon  fa-regular' : 'fa-sun-bright  fa-regular',
    items: themes.map((t) => ({
      title: t,
      value: t,
      action: () => {
        changeTheme(t);
      },
    })),
  });
  if (usePage().props.authenticated && usePage().props.auth.user?.is_admin) {
    array.push({
      type: 'divider',
    });

    adminLinks.forEach((link, index) => {
      array.push({
        title: link.title,
        value: 8 + index,
        preIcon: 'fa-cog fa-regular',
        action: (closeIt: () => void) => {
          if (link.action) return link.action();

          openRoute(link.route);
          closeIt();
        },
      });
    });
  }

  array.push({
    type: 'divider',
  });

  array.push({
    title: 'Logout',
    value: 'Logout',
    preIcon: 'fa-sign-out fa-regular',
    action: (closeIt: () => void) => {
      openRoute('/logout');
      closeIt();
    },
  });

  return array;
};
</script>

<template>
  <VDropdown
    close-on-click
    :items="dropDownList()"
    :with-flex-one="false">
    <template #click-area>
      <VButton
        icon="fa-user fa-regular"
        :title="$page.props.auth.user?.first_name" />
    </template>
  </VDropdown>
</template>
