<script lang="ts" setup>
import { nextTick, onBeforeUnmount, ref } from 'vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { postTask } from '@/services/api-tasks';
import { formatAppModel } from '@/util/format-app-model';
import { TaskOwnerModels, TaskResource } from '@/types/tasks';
import FloatingWindowContainer from '@/components/Inputs/Components/FloatingWindowContainer.vue';
import TaskDateCell from '@/components/Tasks/TaskDateCell.vue';
import { getKey } from '@/util/globals';
import TaskUserCell from '@/components/Tasks/TaskUserCell.vue';
import TaskContextCell from '@/components/Tasks/TaskContextCell.vue';

const props = withDefaults(defineProps<Props>(), {
  inviteId: null,
  eventId: null,
  performanceId: null,
  activePreferenceFilters: () => [],
});

const emit = defineEmits<{
  (e: 'created', value: TaskResource): void;
}>();

type Props = {
  ownerModel: TaskOwnerModels;
  ownerId: number;
  performanceId?: number | null;
  inviteId?: number | null;
  eventId?: number | null;
  activePreferenceFilters?: object[];
};

const newTaskTitle = ref('');
const newTaskDueDate = ref(null);
const newTaskAssignedUserId = ref(null);

const newTaskAssignedContextType = ref(null);
const newTaskAssignedContextId = ref(null);

const addTask = async () => {
  if (!newTaskTitle.value) return;
  let params = {
    'owner_type': formatAppModel(props.ownerModel),
    'owner_id': props.ownerId,
    'title': newTaskTitle.value,
    'event_id': props.eventId,
    'invite_id': props.inviteId,
    'performance_id': props.performanceId,
  };
  if (props.inviteId) {
    params.public = false;
  }

  if (newTaskAssignedContextId.value && newTaskAssignedContextType.value) {
    params.context_type = newTaskAssignedContextType.value;
    params.context_id = newTaskAssignedContextId.value;
  } else if (props.ownerModel === 'Group') {
    params.context_type = 'App\\Group';
    params.context_id = props.ownerId;
  } else if (props.ownerModel === 'User') {
    params.assigned_user_id = props.ownerId;
  }

  if (newTaskAssignedUserId.value) {
    params.assigned_user_id = newTaskAssignedUserId.value;
  }
  if (newTaskDueDate.value) {
    params.due_date = newTaskDueDate.value;
  }

  const { data } = await postTask(params);
  emit('created', data);
  newTaskTitle.value = '';
};

const minWidth = ref(370);
const placeholderInput = ref(null);
const pageX = ref(null);
const pageY = ref(null);
const setFocusForField = ref(false);
const clicked = (event) => {
  pageX.value = null;
  pageY.value = null;
  newTaskAssignedContextType.value = null;
  newTaskAssignedContextId.value = null;
  newTaskAssignedUserId.value = null;
  newTaskDueDate.value = null;
  setFocusForField.value = false;

  props.activePreferenceFilters.forEach((pref) => {
    switch (pref.id) {
      case 'task_my_tasks': {
        newTaskAssignedUserId.value = usePage().props.auth.user.id;
        break;
      }
      case 'task_assigned_group': {
        newTaskAssignedContextId.value = getKey(pref, 'value', null);
        newTaskAssignedContextType.value = 'App\\Group';
        break;
      }
      case 'task_assigned_group_user': {
        newTaskAssignedUserId.value = getKey(pref, 'value', null);
        break;
      }
      default: {
        break;
      }
    }
  });
  if (props.ownerModel === 'FestivalSection') {
    newTaskAssignedContextType.value = 'App\\Models\\Festivals\\FestivalSection';
    newTaskAssignedContextId.value = props.ownerId;
  }
  nextTick(() => {
    if (placeholderInput.value) {
      pageX.value = placeholderInput.value.getBoundingClientRect().x - 1;
      pageY.value = placeholderInput.value.getBoundingClientRect().y - 55;
    } else {
      pageX.value = event.target.getBoundingClientRect().x - 1 - 39;
      pageY.value = event.target.getBoundingClientRect().y - 1 - 54;
    }
  });
  if (placeholderInput.value) {
    minWidth.value = placeholderInput.value.getBoundingClientRect().width + 1;
    if (minWidth.value <= 370) {
      minWidth.value = 370;
    }
  }
  setTimeout(() => {
    setFocusForField.value = true;
  }, 1000);
};
const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Escape') {
    pageX.value = null;
    pageY.value = null;
  }
};
window.addEventListener('keydown', onKeyDown);

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeyDown);
});

const userOpen = ref(false);
const toggleUserOpen = async () => {
  userOpen.value = false;
  await nextTick();
  userOpen.value = true;
};
</script>

<template>
  <div
    class="w-full"
    @click="clicked($event)">
    <div ref="placeholderInput">
      <TextInput
        square
        text-wrapper-class="[&_.slot-start-wrapper]:w-[50px]"
        left-icon="fa-plus"
        placeholder="Create New Task...">
      </TextInput>
    </div>
    <FloatingWindowContainer
      v-if="pageX && pageY"
      :page-x="pageX"
      :page-y="pageY"
      :style="'min-width: ' + minWidth + 'px;'"
      @closed="[(pageX = null), (pageY = null)]">
      <div class="flex flex-col border bg-content max-w-[50vw]">
        <div class="grid grid-cols-3 gap-edge p-edge">
          <TaskContextCell
            v-if="['Group', 'Festival'].includes(ownerModel)"
            key="festival-task-context-selector"
            :task="{
              context_type: newTaskAssignedContextType,
              context_id: newTaskAssignedContextId,
              event_id: eventId,
            }"
            is-create
            @change="$event ? toggleUserOpen() : null"
            @updated="
              [
                (newTaskAssignedContextId = $event.context_id),
                (newTaskAssignedContextType = $event.context_type),
                (newTaskAssignedUserId = null),
              ]
            " />
          <TaskUserCell
            v-if="['Group', 'Festival', 'FestivalSection'].includes(ownerModel)"
            :task="{
              context_type: newTaskAssignedContextType,
              context_id: newTaskAssignedContextId,
              assigned_user_id: newTaskAssignedUserId,
            }"
            :open-dropdown="userOpen"
            is-create
            @new-user-id="newTaskAssignedUserId = $event" />
          <div>
            <TaskDateCell
              :task="{ due_date: newTaskDueDate }"
              class="[&_.button-for-due-date]:min-w-[150px] [&_.button-for-due-date]:max-w-[150px]"
              is-create
              emit-results
              :owner-model="ownerModel"
              @updated="newTaskDueDate = $event.due_date" />
          </div>
        </div>
        <TextInput
          v-model="newTaskTitle"
          square
          text-wrapper-class="[&_.slot-start-wrapper]:w-[50px]"
          left-icon="fa-plus"
          set-focus
          class="text"
          placeholder="Create New Task..."
          @keydown.enter="addTask">
          <template #end>
            <VButton
              :disabled="newTaskTitle.length === 0"
              size="xs"
              class="mr-edge"
              title="Create"
              :emphasized="true"
              type="primary"
              icon="fa-save fa-regular"
              @click="addTask" />
          </template>
        </TextInput>
      </div>
    </FloatingWindowContainer>
  </div>
</template>
