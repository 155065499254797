import type {
  AccommodationPlaceResource,
  AccommodationResource,
  AccommodationRoomsResource,
} from '@/types/accommodation';
import type { AccreditationListResource, AccreditationResource } from '@/types/accreditation';
import { AdvanceSettingsResource } from '@/types/advance-setting';
import type { DocumentFolderResource, DocumentResource, PerformanceDocumentResource } from '@/types/document';
import { DocumentModelType } from '@/types/document';
import {
  EconomyAccountRowResource,
  EconomyArtistFeeRowResource,
  EconomyResource,
  EconomySettingResource,
} from '@/types/economy-setting';
import { FestivalVenueResource } from '@/types/festival-venue';
import {
  EventGroupInviteResource,
  EventInviteResource,
  EventUserInviteResource,
  FestivalSectionInviteResource,
} from '@/types/event';
import { EventGroupResource } from '@/types/event-group';
import type { EventTypeCategoryResource } from '@/types/event-type-category';
import { FestivalTypeResource } from '@/types/festival-type';
import type { GroupRegularResource, ProjectLeader } from '@/types/group';
import { GroupResource } from '@/types/group';
import { PerformancePartnerCompanyResource, PerformancePartnerContactResource } from '@/types/performance-partner';
import type { LocalTravelResource, TravelPlanResource } from '@/types/travel-plan';
import { TravelTypeResource } from '@/types/travel-plan';
import { UserMinimalResource } from '@/types/user';
import { UserInviteResource } from '@/types/user-invite';
import type { VenueRoomResource, VenueSoftAccessListResource } from '@/types/venue';
import type { ComputedRef, InjectionKey, Ref } from 'vue';
import type { PublicFormResource } from '@/types/public-form';
import type { ModelType, PartnerTypesResource } from '@/types/global';
import type {
  FestivalProjectLeadersResource,
  FestivalResource,
  GuestListSettingResource,
  GuestResource,
  RoleResource,
} from '@/types/festival';
import { GuestListResource } from '@/types/festival';
import type {
  PerformanceAssignmentResource,
  PerformanceEventResource,
  PerformanceInfoSectionsResource,
  PerformancePartnerTypeResource,
  PerformanceResource,
  PerformanceTypesResource,
} from '@/types/performance';
import type {
  EventRequestResponseResource,
  EventRequestTemplateResource,
  EventTypeResource,
} from '@/types/event-request';
import { PublicFormResponseShiftSlotResource, ShiftTypeResource } from '@/types/shifts';
import { FestivalSectionDateResource, FestivalSectionResource } from '@/types/festival-section';
import { AdvanceResource } from '@/types/advance';
import { PartnerFieldResource } from '@/types/partners';
import type { CheckListResource } from '@/types/check-list';
import { BoardResource } from '@/types/board';
import { FestivalGlobalTimeResource } from '@/types/festival-global-times';
import { VenueRoomRestrictionResource } from '@/services/api-venue';
import { TaskFieldResource, TaskTemplateListResource } from '@/types/tasks';
import { InviteLinkResource } from '@/types/invite-links';

export type Owner = {
  id: number;
  name: string;
  slug: string;
  type: string;
  start?: string;
  end?: string;
};

export type Permissions = string[];
export type Admin = boolean;

export type GroupResourceComposable = {
  group: ComputedRef<GroupRegularResource | null> | null;
  loading: Ref<boolean>;
  fetch: (force?: boolean) => Promise<void>;
};

export type PartnerTypesComposable = {
  partnerTypes: ComputedRef<PartnerTypesResource[]>;
  loading: Ref<boolean>;
  fetchPartnerTypes: (force?: boolean) => Promise<void>;
  removeType: (typeId: number) => Promise<void>;
  addOrUpdateType: (type: PartnerTypesResource) => Promise<void>;
};

export type PartnerFieldsComposable = {
  partnerFields: ComputedRef<PartnerFieldResource[]>;
  loading: Ref<boolean>;
  fetchPartnerFields: (force?: boolean) => Promise<void>;
  removeField: (fieldId: number) => Promise<void>;
  addOrUpdateField: (field: PartnerFieldResource) => Promise<void>;
};

export type TaskFieldsComposable = {
  taskFields: ComputedRef<TaskFieldResource[]>;
  loading: Ref<boolean>;
  fetchTaskFields: (force?: boolean) => Promise<void>;
  removeField: (fieldId: number) => Promise<void>;
  addOrUpdateField: (field: TaskFieldResource) => Promise<void>;
  reorderFields: (fieldIds: number | string[]) => Promise<void>;
};

export type AccommodationPlaceComposable = {
  loading: Ref<boolean>;
  accommodationPlaces: ComputedRef<AccommodationPlaceResource[]>;
  fetchAccommodationPlaces: (force?: boolean) => void;
  addOrUpdateAccommodationPlaces: (accommodationPlace: AccommodationPlaceResource) => void;
  removeAccommodationPlace: (accommodationPlaceId: number) => void;
};

export type AccommodationRoomsComposable = {
  loading: Ref<boolean>;
  accommodationRooms: ComputedRef<AccommodationRoomsResource[]>;
  fetchAccommodationRooms: (force?: boolean) => Promise<void>;
  addOrUpdateAccommodationRooms: (accommodationRoom: AccommodationRoomsResource) => void;
  removeAccommodationRoom: (accommodationRoomId: number) => void;
};

export type GroupProjectLeadersComposable = {
  loading: Ref<boolean>;
  projectLeaders: ComputedRef<ProjectLeader[]>;
  fetchGroupProjectLeaders: (force?: boolean) => void;
};

export type PerformanceDocumentsComposable = {
  canView: Ref<boolean>;
  documents: Ref<(DocumentResource | PerformanceDocumentResource)[]>;
  fetchDocuments: (forceFetch?: boolean) => void;
  removeDocument: (documentId: number) => void;
  addOrUpdateDocument: (document: DocumentResource) => void;
  clearDocuments: () => void;
  loading: Ref<boolean>;
  hasLoaded: Ref<boolean>;
  images: ComputedRef<(DocumentResource | PerformanceDocumentResource)[]>;
};

export type PerformanceInfoSectionsComposable = {
  performanceInfoSections: ComputedRef<PerformanceInfoSectionsResource[]>;
  loading: Ref<boolean>;
  fetchPerformanceInfoSections: (force?: false) => void;
};

export interface PerformanceAssignmentsComposable {
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  assignments: Ref<PerformanceAssignmentResource[]>;
  fetchAssignments: (force?: boolean) => void;
}

export type TravelPlanTypeComposable = {
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  travelPlans: Ref<TravelPlanResource[]>;
  removeTravelPlan: (id: number) => Promise<void>;
  addOrUpdateTravelPlan: (travelPlan: TravelPlanResource) => Promise<void>;
  fetch: (force?: boolean, id?: number | null) => Promise<TravelPlanResource[]>;
};

export type FestivalPartnerContactTravelPlanComposable = {
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  travelPlans: Ref<TravelPlanResource[]>;
  fetch: (force?: boolean, id?: number | null) => Promise<void>;
};

export type FestivalPartnerContactLocalTravelComposable = {
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  localTravels: Ref<LocalTravelResource[]>;
  getLocalTravels: (force?: boolean, id?: number | null) => Promise<void>;
};

export type GuestListTypeComposable = {
  canView: Ref<boolean>;
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  guestList: Ref<GuestListResource | null>;
  removeGuest: (id: number) => Promise<void>;
  addOrUpdateGuest: (guest: GuestResource) => Promise<void>;
  fetch: (force?: boolean) => Promise<void>;
};
export type PerformanceEconomyTypeComposable = {
  canView: boolean;
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  economy: Ref<EconomyResource | null>;
  artistFeeRows: Ref<EconomyArtistFeeRowResource[]>;
  expensesRows: Ref<EconomyAccountRowResource[]>;
  addOrUpdateArtistFeeRow: (row: EconomyArtistFeeRowResource) => Promise<void>;
  removeArtistFeeRow: (rowId: number) => Promise<void>;
  addOrUpdateExpenseRow: (row: EconomyAccountRowResource) => Promise<void>;
  removeExpenseRow: (rowId: number) => Promise<void>;
  updateEconomy: (newEconomy: object) => void;
  fetch: (force?: boolean) => Promise<void>;
};

export type PerformanceTravelTypeComposable = {
  canView: Ref<boolean>;
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  travelPlans: Ref<TravelPlanResource[]>;
  localTravels: Ref<LocalTravelResource[]>;
  removeTravelPlan: (id: number) => Promise<void>;
  addOrUpdateTravelPlan: (travelPlan: TravelPlanResource) => Promise<void>;
  fetchTravelPlans: (force?: boolean) => Promise<void>;
  removeLocalTravel: (id: number) => Promise<void>;
  addOrUpdateLocalTravel: (localTravel: LocalTravelResource) => Promise<void>;
  fetchLocalTravels: (force?: boolean) => Promise<void>;
};

export type PerformanceAccreditationsTypeComposable = {
  canView: boolean;
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  accreditations: Ref<AccreditationResource[]>;
  removeAccreditation: (id: number) => Promise<void>;
  addOrUpdateAccreditation: (guest: AccreditationResource) => Promise<void>;
  fetch: (force?: boolean) => Promise<void>;
};

export type TravelTypeComposable = {
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  travelTypes: Ref<TravelTypeResource[]>;
  fetch: (force?: boolean) => Promise<void>;
};

export type PerformanceContactComposable = {
  partnerCompanies: Ref<PerformancePartnerCompanyResource[]>;
  partnerContacts: Ref<PerformancePartnerContactResource[]>;
  loaded: Ref<boolean>;
  loading: Ref<boolean>;
  getContacts: (force?: boolean) => Promise<void>;
};

export type GuestListSettingComposable = {
  guestListSetting: Ref<GuestListSettingResource>;
  loaded: Ref<boolean>;
  loading: Ref<boolean>;
  getGuestListSetting: (force?: boolean) => Promise<void>;
};

export type PerformanceLocalTravelComposable = {
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  localTravels: Ref<LocalTravelResource[]>;
  removeLocalTravel: (id: number) => Promise<void>;
  addOrUpdateLocalTravel: (localTravel: LocalTravelResource) => Promise<void>;
  getLocalTravels: (force?: boolean) => Promise<void>;
};

export type PerformanceAccommodationComposable = {
  canView: Ref<boolean>;
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  removeAccommodation: (id: number) => Promise<void>;
  addOrUpdateAccommodation: (accommodation: AccommodationResource) => Promise<void>;
  accommodations: Ref<AccommodationResource[]>;
  fetch: (force?: boolean, id?: number | null) => Promise<AccommodationResource[]>;
};

export type AdvanceComposable = {
  canView: Ref<boolean>;
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  deleteAdvance: () => Promise<void>;
  advance: Ref<AdvanceResource | null>;
  getAdvance: (force?: boolean) => Promise<void>;
  addAdvance: (advanceableName: string) => Promise<void>;
};

export type AccreditationsComposable = {
  loading: Ref<boolean>;
  fetchAccreditationList: (force?: boolean) => Promise<void>;
  accreditationList: ComputedRef<AccreditationListResource | null>;
};

export type EconomySettingsComposable = {
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  economySetting: Ref<EconomySettingResource>;
  getEconomySetting: (force?: boolean) => Promise<void>;
  clearEconomySetting: () => Promise<void>;
};

export type FestivalProjectLeadersComposable = {
  loading: Ref<boolean>;
  fetchProjectLeaders: (force?: boolean) => Promise<void>;
  festivalProjectLeaders: ComputedRef<FestivalProjectLeadersResource[] | undefined>;
};

export type FestivalSectionsComposable = {
  loading: Ref<boolean>;
  fetchSections: (force?: boolean) => Promise<void>;
  festivalSections: ComputedRef<FestivalSectionResource[] | undefined>;
};

export type FestivalGlobalTimesComposable = {
  loading: Ref<boolean>;
  fetchGlobalTimes: (force?: boolean) => Promise<void>;
  globalTimes: ComputedRef<FestivalGlobalTimeResource[] | undefined>;
};
export type FestivalSectionDatesComposable = {
  updateDates: (date: object) => Promise<void>;
  dates: ComputedRef<FestivalSectionDateResource[]>;
  currentDate: Ref<string>;
};

export type rolesComposable = {
  loading: Ref<boolean>;
  fetchRoles: (force?: boolean) => Promise<void>;
  addPermissionToRole: (roleId: number, permissionId: number) => Promise<void>;
  removePermissionFromRole: (roleId: number, permissionId: number) => Promise<void>;
  roles: ComputedRef<RoleResource[] | undefined>;
};

export interface publicFormsComposable {
  readonly loading: Ref<boolean>;
  fetchPublicForms: (force?: boolean) => Promise<void>;
  publicForms: Readonly<Ref<Readonly<PublicFormResource[]>>>;
}

export type PublicFormResponseShiftsComposable = {
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  shifts: Ref<PublicFormResponseShiftSlotResource[]>;
  ongoingShiftSlots: Ref<PublicFormResponseShiftSlotResource[]>;
  fetch: (force?: boolean) => Promise<void>;
  removeSlot: (id: number) => Promise<void>;
  updateSlot: (shift: PublicFormResponseShiftSlotResource) => Promise<void>;
};

export type PublicFormResponseWorkMinutesComposable = {
  assignedWorkMinutes: Ref<number>;
  completedWorkMinutes: Ref<number>;
  updateAssignedMinutes: (change: number) => Promise<void>;
  updateCompletedMinutes: (change: number) => Promise<void>;
};

export type PerformancePartnerTypesComposable = {
  loading: Ref<boolean>;
  fetchPerformancePartnerTypes: (force?: boolean) => Promise<void>;
  performancePartnerTypes: ComputedRef<PerformancePartnerTypeResource[] | undefined>;
};

export type PerformanceTypesComposable = {
  loading: Ref<boolean>;
  fetchPerformanceTypes: (force?: boolean) => Promise<void>;
  addOrUpdatePerformanceTypes: (performanceType: PerformanceTypesResource) => Promise<void>;
  removePerformanceType: (performanceTypeId: number) => Promise<void>;
  performanceTypes: ComputedRef<PerformanceTypesResource[]>;
  changeOutList: (performanceType: PerformanceTypesResource[]) => void;
};

type UseSimpleStoreComposable<T> = {
  loading: Ref<boolean>;
  fetch: (force?: boolean) => Promise<void>;
};

type UseStoreComposable<T> = {
  loading: Ref<boolean>;
  fetch: (force?: boolean) => Promise<void>;
  addItem: (item: T) => void;
  removeItem: (id: number) => void;
  updateItem: (item: T) => void;
};

export type PerformancePartnersComposable = {
  canView: Ref<boolean>;
  addOrUpdateContact?: (item: PerformancePartnerContactResource) => void;
  removeContact?: (item: number) => void;
  addOrUpdateCompany?: (item: PerformancePartnerCompanyResource) => void;
  removeCompany?: (item: number) => void;
  loading?: Ref<boolean>;
  loaded?: Ref<boolean>;
  fetch: (force?: boolean) => Promise<void>;
  contacts: ComputedRef<PerformancePartnerContactResource[]>;
  companies: ComputedRef<PerformancePartnerCompanyResource[]>;
};

export type EventTypesComposable = UseStoreComposable<EventTypeResource> & {
  eventTypes: ComputedRef<EventTypeResource[]>;
};

export type CheckListsComposable = UseStoreComposable<CheckListResource> & {
  checkLists: ComputedRef<CheckListResource[]>;
};

export type BoardsComposable = UseStoreComposable<BoardResource> & {
  boards: ComputedRef<BoardResource[]>;
};

export type TimeslotComposable = UseSimpleStoreComposable<any> & {
  timeSlots: ComputedRef<any[]>;
  flushForModel: () => void;
};

export type AdvanceSettingsComposable = UseSimpleStoreComposable<AdvanceSettingsResource> & {
  advanceSetting: ComputedRef<AdvanceSettingsResource | null>;
};

export type FestivalVenuesComposable = UseSimpleStoreComposable<FestivalVenueResource[]> & {
  festivalVenues: ComputedRef<FestivalVenueResource[]>;
};

export type FestivalEventTypesComposable = UseStoreComposable<FestivalTypeResource> & {
  festivalEventTypes: ComputedRef<FestivalTypeResource[]>;
};

export type EventRequestTemplatesComposable = UseStoreComposable<EventRequestTemplateResource> & {
  eventRequestTemplates: ComputedRef<EventRequestTemplateResource[]>;
};

export type EventRequestResponsesComposable = UseStoreComposable<EventRequestResponseResource> & {
  eventRequestResponses: ComputedRef<EventRequestResponseResource[]>;
};

export type EventTypesCategoriesComposable = UseStoreComposable<EventTypeCategoryResource> & {
  eventTypesCategories: ComputedRef<EventTypeCategoryResource[]>;
};

export type VenueRoomsComposable = UseStoreComposable<VenueRoomResource> & {
  venueRooms: ComputedRef<VenueRoomResource[]>;
  fetch: (force?: boolean) => Promise<void>;
  addItem: (item: VenueRoomResource) => void;
  updateItem: (item: VenueRoomResource) => void;
  removeItem: (item: number) => void;
  readonly loading: Ref<boolean>;
};

export type VenueRoomRestrictionsComposable = UseStoreComposable<VenueRoomRestrictionResource> & {
  roomRestrictions: ComputedRef<VenueRoomRestrictionResource[]>;
  removeRestriction: (item: number) => void;
  addOrUpdateRestriction: (item: VenueRoomRestrictionResource) => void;
  fetch: (force?: boolean) => Promise<void>;
  readonly loading: Ref<boolean>;
};

export type VenueSoftAccessListsComposable = UseStoreComposable<VenueSoftAccessListResource> & {
  venueSoftAccessLists: ComputedRef<VenueSoftAccessListResource[]>;
  removeList: (item: number) => void;
  addList: (item: VenueSoftAccessListResource) => void;
  updateList: (item: VenueSoftAccessListResource) => void;
  loading: boolean;
  fetch: (force?: boolean) => Promise<void>;
};

export type ShiftTypesComposable = UseStoreComposable<ShiftTypeResource> & {
  shiftTypes: ComputedRef<ShiftTypeResource[]>;
};

export type TaskTemplateListComposable = {
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  taskTemplateLists: Ref<TaskTemplateListResource[]>;
  removeTaskTemplateList: (id: number) => Promise<void>;
  addOrUpdateTaskTemplateLists: (taskTemplateList: TaskTemplateListResource) => Promise<void>;
  fetchTaskTemplateLists: (force?: boolean, id?: number | null) => Promise<TaskTemplateListResource[]>;
};

export type Module =
  | 'SOFT_ACCESS_LISTS'
  | 'CHECK_LIST'
  | 'INVOICE_BASES'
  | 'META_DATA'
  | 'EVENT_REQUEST'
  | 'PARTNERS'
  | 'FESTIVALS'
  | 'ACCOMMODATIONS'
  | 'PERFORMANCES'
  | 'EMAIL_CONFIG'
  | 'SHIFTS'
  | 'RECURRING'
  | 'SIMPLE_EVENTS'
  | 'INVITE_RESPONSES'
  | 'TASKS'
  | 'IS_VENUE';

export type DocumentComposable = {
  loading: Ref<boolean>;
  loaded: Ref<boolean>;
  fetch: (force?: boolean) => Promise<void>;
  unconnectedDocuments: Ref<DocumentResource[]>;
  folderDocuments: Ref<DocumentFolderResource[]>;
  allDocuments: ComputedRef<DocumentResource[]>;
  model: DocumentModelType;
  modelId: number;
};

// Documents
export const maxUploadSizeKey: InjectionKey<number> = Symbol('document size');
export const documentKey: InjectionKey<DocumentComposable> = Symbol('document composable');
// Globals
export const editModeKey: InjectionKey<Readonly<Ref<boolean>>> = Symbol('edit mode');
export const modelIdKey: InjectionKey<number> = Symbol('model id');
export const modelTypeKey: InjectionKey<ModelType> = Symbol('model type');
export const modelKey: InjectionKey<string> = Symbol('model');
export const canEditKey: InjectionKey<boolean> = Symbol('can edit');
export const permissionKey: InjectionKey<string[] | []> = Symbol('permission');
export const adminKey: InjectionKey<boolean> = Symbol('admin');
export const groupKey: InjectionKey<GroupResource> = Symbol('group');
export const groupResourceKey: InjectionKey<GroupResourceComposable> = Symbol('group-resource');
export const ownerKey: InjectionKey<Owner | null> = Symbol('owner');
export const partnerTypesContactsKey: InjectionKey<PartnerTypesComposable> = Symbol('partner contacts types');
export const partnerTypesCompaniesKey: InjectionKey<PartnerTypesComposable> = Symbol('partner company types');
export const partnerFieldsKey: InjectionKey<PartnerFieldsComposable> = Symbol('partner fields');
export const accommodationPlaceKey: InjectionKey<AccommodationPlaceComposable> = Symbol('accommodation place');
export const accommodationRoomsKey: InjectionKey<AccommodationRoomsComposable> = Symbol('accommodation rooms');
export const groupProjectLeadersKey: InjectionKey<GroupProjectLeadersComposable> = Symbol('group project leaders');
export const shiftTypesKey: InjectionKey<ShiftTypesComposable> = Symbol('shift types');
export const eventTypesKey: InjectionKey<EventTypesComposable> = Symbol('Event Types');
export const CheckListsKey: InjectionKey<CheckListsComposable> = Symbol('Check Lists');
export const BoardsKey: InjectionKey<BoardsComposable> = Symbol('Template Boards');
export const advanceSettingsKey: InjectionKey<AdvanceSettingsComposable> = Symbol('advance settings');
export const festivalVenuesKey: InjectionKey<FestivalVenuesComposable> = Symbol('festival venues');
export const festivalEventTypesKey: InjectionKey<FestivalEventTypesComposable> = Symbol('Festival Event Types');
export const eventRequestTemplatesKey: InjectionKey<EventRequestTemplatesComposable> =
  Symbol('Event Request Templates');
export const eventRequestResponsesKey: InjectionKey<EventRequestResponsesComposable> =
  Symbol('Event Request Responses');
export const eventTypesCategoriesKey: InjectionKey<EventTypesCategoriesComposable> = Symbol('Event Types');
export const venueRoomsKey: InjectionKey<VenueRoomsComposable> = Symbol('Venue Rooms');
export const venueRoomRestrictionsKey: InjectionKey<VenueRoomRestrictionsComposable> =
  Symbol('Venue Room Restrictions');
export const hasModuleKey: InjectionKey<(module: Module) => boolean> = Symbol('has module function');
export const taskTemplateListsKey: InjectionKey<TaskTemplateListComposable> = Symbol('Task Template Lists');

// Festival
export const festivalKey: InjectionKey<FestivalResource> = Symbol('festival');
export const publicFormResourceKey: InjectionKey<PublicFormResource> = Symbol('Public From Resource');
export const accreditationKey: InjectionKey<AccreditationsComposable> = Symbol('accreditation list');
export const festivalProjectLeadersKey: InjectionKey<FestivalProjectLeadersComposable> =
  Symbol('Festival Project Leaders');
export const festivalSectionsKey: InjectionKey<FestivalSectionsComposable> = Symbol('Festival Sections');
export const festivalSectionKey: InjectionKey<FestivalSectionResource> = Symbol('Festival Section');
export const rolesKey: InjectionKey<rolesComposable> = Symbol('Roles');
export const publicFormsKey: InjectionKey<publicFormsComposable> = Symbol('public forms');
export const festivalSectionDatesKey: InjectionKey<FestivalSectionDatesComposable> = Symbol('Festival Section Dates');
export const festivalPartnerContactTravelPlansKey: InjectionKey<FestivalPartnerContactTravelPlanComposable> = Symbol(
  'Festival partner contacts travel plans'
);
export const festivalPartnerContactLocalTravelKey: InjectionKey<FestivalPartnerContactLocalTravelComposable> = Symbol(
  'Festival partner contacts local travel '
);
export const hasSMSKey: InjectionKey<boolean> = Symbol('Has SMS');
export const festivalGlobalTimesKey: InjectionKey<FestivalGlobalTimesComposable> = Symbol('Festival Global Times');

// Performances
export const performancePartnerTypesKey: InjectionKey<PerformancePartnerTypesComposable> =
  Symbol('performance partner types');
export const performanceTypesKey: InjectionKey<PerformanceTypesComposable> = Symbol('performance types');
export const performanceAccommodationKey: InjectionKey<PerformanceAccommodationComposable> =
  Symbol('performance accommodation');
export const performanceLocalTravelKey: InjectionKey<PerformanceLocalTravelComposable> =
  Symbol('performance local travel');
export const performanceEventsKey: InjectionKey<PerformanceEventResource[]> = Symbol('performance events');
export const performanceTravelPlansKey: InjectionKey<TravelPlanTypeComposable> = Symbol('performance travel plans');
export const performanceKey: InjectionKey<PerformanceResource> = Symbol('performance');
// export const performanceContactKey: InjectionKey<PerformanceContactComposable> = Symbol('performance contacts');
export const performanceGuestListKey: InjectionKey<GuestListTypeComposable> = Symbol('performance guest list');
export const performanceTravelKey: InjectionKey<PerformanceTravelTypeComposable> = Symbol('performance travels');
export const performanceAccreditationsKey: InjectionKey<PerformanceAccreditationsTypeComposable> =
  Symbol('performance accreditations');
export const performanceEconomyKey: InjectionKey<PerformanceEconomyTypeComposable> = Symbol('performance economy');
export const performanceFirstEventDateKey: InjectionKey<string | null> = Symbol('performance first event date');
export const performanceLastEventDateKey: InjectionKey<string | null> = Symbol('performance last event date');

export const advanceKey: InjectionKey<AdvanceComposable> = Symbol('advance');

export const travelTypesKey: InjectionKey<TravelTypeComposable> = Symbol('travel types');

export const performancePartnersKey: InjectionKey<PerformancePartnersComposable> = Symbol('performance partners');

export const performanceDocumentsKey: InjectionKey<PerformanceDocumentsComposable> = Symbol('performance documents');
export const performanceInfoSectionsKey: InjectionKey<PerformanceInfoSectionsComposable> =
  Symbol('performance info sections');
export const performanceAssignmentsKey: InjectionKey<PerformanceAssignmentsComposable> =
  Symbol('performance assignments');

export const guestListSettingKey: InjectionKey<GuestListSettingComposable> = Symbol('Guest List Setting');

// Misc
export const startKey: InjectionKey<string | null> = Symbol('start');
export const endKey: InjectionKey<string | null> = Symbol('end');

export const stateFromUrlKey: InjectionKey<string | null> = Symbol('state from url');

export const showModeKey: InjectionKey<boolean> = Symbol('show mode');

export type EventMembersComposable = {
  loaded: Ref<boolean>;
  loading: Ref<boolean>;
  fetch: () => Promise<void>;
  eventGroups: Ref<EventGroupResource[]>;
  userInvites: Ref<UserInviteResource[]>;
  invites: Ref<EventInviteResource | null>;
  acceptedInvitesOfGroups: ComputedRef<EventGroupInviteResource[]>;
  pendingInvitesOfGroups: ComputedRef<EventGroupInviteResource[]>;
  declinedInvitesOfGroups: ComputedRef<EventGroupInviteResource[]>;
  acceptedInvitesOfUsers: ComputedRef<EventUserInviteResource[]>;
  pendingInvitesOfUsers: ComputedRef<EventUserInviteResource[]>;
  declinedInvitesOfUsers: ComputedRef<EventUserInviteResource[]>;
  acceptedInvitesOfFestivalSections: ComputedRef<FestivalSectionInviteResource[]>;
  pendingInvitesOfFestivalSections: ComputedRef<FestivalSectionInviteResource[]>;
  declinedInvitesOfFestivalSections: ComputedRef<FestivalSectionInviteResource[]>;
  allMembers: ComputedRef<UserMinimalResource[]>;
  allInvitesOfUsers: ComputedRef<EventUserInviteResource[]>;
  allInvitesOfGroups: ComputedRef<EventGroupInviteResource[]>;
};
export const eventMembersKey: InjectionKey<EventMembersComposable> = Symbol('event members');

// Public Form Responses
export const publicFormResponseShiftsKey: InjectionKey<PublicFormResponseShiftsComposable> =
  Symbol('public form response shifts');

export const publicFormResponseWorkMinutesKey: InjectionKey<PublicFormResponseWorkMinutesComposable> = Symbol(
  'public form response work minutes'
);

export type getTravelPlanProvider = (id: number) => Promise<TravelPlanResource>;
export const getTravelPlanKey: InjectionKey<getTravelPlanProvider> = Symbol('get travel plan function');

export const timeSlotsKey: InjectionKey<TimeslotComposable> = Symbol('timeslots');

export const adminSameTabClickKey: InjectionKey<Ref<boolean>> = Symbol('admin same tab click');

export const extraPixelsOverContentContainer: InjectionKey<Ref<number>> = Symbol(
  'Extra pixels to be added above content container'
);

export type GroupCreatedEventsComposable = {
  unlimitedEvents: Ref<boolean | null>;
  allowedEvents: Ref<number | null>;
  createdEvents: Ref<number | null>;
  eventCreated: () => void;
  setEventNumbers: () => void;
};

export const groupCreatedEventsCount: InjectionKey<GroupCreatedEventsComposable | null> =
  Symbol('group created events count');
export const taskFieldsKey: InjectionKey<TaskFieldsComposable> = Symbol('task fields');

export const advanceSectionOwnerNameKey: InjectionKey<string> = Symbol('Advance Section Owner Name');

export type InviteLinkComposable = {
  loading: Ref<boolean>;
  inviteLinks: Ref<InviteLinkResource[]>;
  getInviteLinks: (force?: boolean) => void;
  addOrUpdateInviteLink: (item: InviteLinkResource) => void;
  removeInviteLink: (item: number) => void;
};
export const inviteLinkKey: InjectionKey<InviteLinkComposable> = Symbol('inviteLinks');
export const mainRowType: InjectionKey<() => boolean> = Symbol('main_row');
export const currentHoverIdType: InjectionKey<{
  currentHoverId: string;
  update: (newNum: string) => void;
}> = Symbol('current_hover_id');

export const widthOfContentKey: InjectionKey<Ref<number>> = Symbol('widthOfContent');
export const leftOfContentKey: InjectionKey<Ref<number>> = Symbol('leftOfContent');
export const contentListElement: InjectionKey<string> = Symbol('contentListElement');
export const inputInTable: InjectionKey<boolean> = Symbol('input in table');
