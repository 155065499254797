<script lang="ts" setup>
import DatePickerDropdown from '@/components/Inputs/Date/DatePickerDropdown.vue';
import VButton from '@/components/Inputs/VButton.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { computed, ref } from 'vue';
import { isAfter } from '@/util/date';
import moment from 'moment';
import { slotEmpty } from '@/util/globals';
import ActionButtonGroup from '@/components/Inputs/Components/ActionButtonGroup.vue';
import VDatepicker from '@/components/Inputs/Date/VDatepicker.vue';

type Props = {
  views: string[];
  currentView: string;
  start: string;
  title: string;
  showDefaultActions?: boolean;
  showTitle?: boolean;
  showViewSelector?: boolean;
  itemsForViewSelector?: array | null;
  allowedDates?: {
    allowedStart: string;
    allowedEnd: string;
  };
  withToday?: boolean;
  titleIsClickableDate?: boolean | null;
  actions?: [] | null;
  sticky?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  showDefaultActions: true,
  showTitle: true,
  showViewSelector: true,
  titleIsClickableDate: false,
  allowedDates: {
    allowedStart: '',
    allowedEnd: '',
  },
  actions: () => [],
  itemsForViewSelector: () => [],
  withToday: true,
  sticky: false,
});

defineEmits<{
  (e: 'update:current-view', value: string): void;
  (e: 'today'): void;
  (e: 'past'): void;
  (e: 'future'): void;
  (e: 'date-change', value: string): void;
}>();

const formatViewName = (viewName: string) => {
  if (!viewName.includes('_')) return viewName;

  return viewName.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

const { isSmallScreen } = useSmallScreen();

const gridCols = computed(() => {
  if (isSmallScreen.value) return 'grid-cols-1';
  if (props.showDefaultActions && props.showTitle) return 'grid-cols-3';
  if (props.showTitle || props.showDefaultActions) return 'grid-cols-2';
  return 'grid-cols-1';
});

const shouldBeMonthPicker = computed(() => {
  return props.currentView === 'month';
});

const allowedToGoBack = computed(() => {
  if (!props.allowedDates.allowedStart) return true;

  return isAfter(props.start, props.allowedDates.allowedStart);
});

const allowedToGoForward = computed(() => {
  if (!props.allowedDates.allowedEnd) return true;
  return moment(props.start).isBefore(props.allowedDates.allowedEnd, 'month');
});

const earliestDate = computed(() => {
  if (!props.allowedDates.allowedStart) return undefined;
  return moment(props.allowedDates.allowedStart).startOf('month').toString();
});
const startOnYear = ref(false);
</script>

<template>
  <div class="bg space-y-edge px-edge">
    <div
      :class="gridCols"
      class="flex justify-between md:items-center gap-edge md:gap-0 flex-col md:flex-row flex-wrap">
      <div class="flex gap-edge-1/2 items-center">
        <div
          v-if="showDefaultActions"
          class="flex justify-center gap-edge-1/2 md:justify-start">
          <template v-if="currentView !== 'list'">
            <VButton
              v-if="withToday"
              title="Today"
              @click="$emit('today')" />
            <slot
              v-else
              name="today-button" />
            <div class="flex items-center gap-1">
              <VButton
                data-intro="previous-date"
                :disabled="!allowedToGoBack"
                wrapper-class="h-full"
                :emphasized="false"
                icon="fa-chevron-left"
                @click="$emit('past')" />

              <DatePickerDropdown
                :highlight-days="[start]"
                :month-picker="shouldBeMonthPicker"
                :start="start"
                :max-date="allowedDates?.allowedEnd ?? undefined"
                :earliest-date="earliestDate"
                @new-date="$emit('date-change', $event)">
                <template #trigger>
                  <VButton
                    icon="fa-calendar"
                    :emphasized="false"
                    wrapper-class="h-full" />
                </template>
              </DatePickerDropdown>
              <VButton
                icon="fa-chevron-right"
                wrapper-class="h-full"
                :emphasized="false"
                :disabled="!allowedToGoForward"
                @click="$emit('future')" />
            </div>
          </template>
          <div v-if="!slotEmpty($slots, 'header-left')">
            <slot name="header-left" />
          </div>
        </div>
        <VDatepicker
          v-if="showTitle && titleIsClickableDate"
          :model-value="start"
          required
          month-picker
          :start-date="start"
          :min-date="null"
          @update:model-value="$emit('date-change', $event)">
          <template #trigger>
            <VButton>
              <div class="mx-auto flex cursor-pointer items-center justify-center text-center">
                <h3 class="truncate">{{ title }}</h3>
              </div>
            </VButton>
          </template>
        </VDatepicker>
        <h3
          v-else-if="showTitle"
          :class="{ 'text-base my-auto ml-auto': isSmallScreen }">
          {{ title }}
        </h3>
      </div>
      <h3
        v-if="showTitle || $slots.title"
        class="text-center">
        <slot
          name="title"
          :data="title">
          {{ title }} 22
        </slot>
      </h3>
      <div class="flex md:justify-end gap-edge-1/2 ml-auto flex-wrap">
        <div>
          <slot />
        </div>
        <div>
          <ActionButtonGroup
            :actions="
              actions
                .concat([
                  showViewSelector && views.length > 1
                    ? {
                        withIcon: false,
                        title: currentView.replace('_', ' ') + ' View',
                        postIcon: 'fa-chevron-down ',
                        position: -25,
                        emphasized: true,
                        dropdown: views
                          .map((v) => {
                            const viewName = v.replace('_', ' ');
                            return {
                              title: viewName[0].toUpperCase() + viewName.slice(1) + ' View',
                              postIcon: currentView === v ? 'fa-check' : '',
                              emphasized: true,
                              action: (close) => {
                                $emit('update:current-view', v);
                                close();
                              },
                            };
                          })
                          .concat(itemsForViewSelector),
                      }
                    : null,
                ])
                .filter((i) => i !== null)
            " />
          <slot name="heading-right" />
        </div>
      </div>
    </div>
    <div
      v-if="!slotEmpty($slots, 'under-heading')"
      class="col-span-full col-start-1">
      <slot name="under-heading" />
    </div>
  </div>
</template>
