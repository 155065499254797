export const white = '#ffffff'.toLowerCase();
export const blue = '#8ADCFF'.toLowerCase();
export const green = '#70FF8B'.toLowerCase();
export const newGreen = '#1b998b'.toLowerCase();
export const red = '#E976A4'.toLowerCase();

export const highlight = '#2AB8A8'.toLowerCase();

export const transparent = 'transparent';
export const yellow = '#FFF34F'.toLowerCase();
export const black = '#00141C'.toLowerCase();
export const textBlack = black;
export const textWhite = '#E7E7DE'.toLowerCase();
export const lightBlue = '#13577B'.toLowerCase();
export const darkBlue = '#072c3b'.toLowerCase();

export const beige = '#EAF0CE'.toLowerCase();
export const yellowGreen = '#C1CE94'.toLowerCase();
export const salomon = '#EBA0A7'.toLowerCase();
export const angryRed = '#E68778'.toLowerCase();

export const labeledColorList = [
  { label: 'Jordy Blue', color: '#bccde2'.toLowerCase() },
  { label: 'Beige', color: beige.toLowerCase() },
  { label: 'Yellow', color: '#f7e664'.toLowerCase() },
  { label: 'Yellow-Green', color: yellowGreen.toLowerCase() },
  { label: 'Pistachio', color: '#9FC89F'.toLowerCase() },
  { label: 'Pink', color: '#F2B6CD'.toLowerCase() },
  { label: 'Salomon', color: salomon.toLowerCase() },
  { label: 'Brown', color: '#C2B1A1'.toLowerCase() },
  { label: 'Purple', color: '#C49FC5'.toLowerCase() },
  { label: 'Orange', color: '#FFB54E'.toLowerCase() },
  { label: 'Raspberry', color: red.toLowerCase() },
  { label: 'Red', color: angryRed.toLowerCase() },
];

export const colorList = labeledColorList.map((c) => c.color);
