<script lang="ts" setup>
import { inject, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import InputLabel from '@/components/Inputs/InputLabels/InputLabel.vue';
import InputErrorText from '@/components/Inputs/InputLabels/InputErrorText.vue';
import WordExchanger from '@/components/Fields/WordExchanger/WordExchanger.vue';
import { addWordToContentOfField, sanitizeValueForExchangeWords } from '@/util/exchangeFunctions';
import { whisperForArea, WhisperObject } from '@/util/whisper-functions';
import { blurActiveElement, focusOnField } from '@/util/globals';
import WhisperIndicator from '@/components/Broadcasting/WhisperIndicator.vue';
import DataList from '@/components/Inputs/Components/DataList.vue';
import { inputInTable } from '@/provide/keys';

type Props = {
  modelValue?: string | null | boolean;
  label?: string | null;
  labelTitle?: string | null;
  placeholder?: string;
  maxLength?: number;
  errorMessage?: string | null;
  canEdit?: boolean;
  isHidden?: boolean;
  square?: boolean;
  formError?: string | null;
  required?: boolean;
  autoResize?: boolean;
  minRows?: number;
  wrapperClass?: string | null;
  autoFocus?: boolean;
  setFocus?: boolean;
  watchForFocusChange?: boolean;
  emitBlurAsModelValue?: boolean;
  exchangeWords?: string[];
  dataListOptions?: string[];
  newLineOnEnter?: boolean;
  minHeight?: number;
  tabindex?: number;
  whisper?: WhisperObject | null;
  iconLeft?: string | null;
  inTableStyle?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  label: null,
  labelTitle: null,
  iconLeft: null,
  placeholder: '',
  maxLength: 9999999999,
  errorMessage: null,
  canEdit: true,
  isHidden: false,
  square: false,
  formError: null,
  required: false,
  autoResize: true,
  minRows: 5,
  minHeight: 100,
  wrapperClass: null,
  autoFocus: false,
  setFocus: false,
  watchForFocusChange: true,
  emitBlurAsModelValue: false,
  exchangeWords: () => [],
  dataListOptions: () => [],
  newLineOnEnter: true,
  tabindex: null,
  whisper: null,
  inTableStyle: false,
});

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void;
  (event: 'blur', value: string | null): void;
  (event: 'focus', value: string | null): void;
  (event: 'dataListSelected', value: string | null): void;
  (event: 'keydown-esc', value: KeyboardEvent): void;
}>();

const inTable = inject(inputInTable, props.inTableStyle);

const textArea = ref<HTMLTextAreaElement | null>(null);
const inFocus = ref(false);

const currentDataListOption = ref(null);
const tempValue = ref(null);

const setHeight = () => {
  if (textArea.value) {
    textArea.value.style.height = `${props.minHeight}px`;
  }
  nextTick(() => {
    if (textArea.value) {
      if (textArea.value.scrollHeight > props.minHeight) {
        textArea.value.style.height = `${textArea.value.scrollHeight}px`;
      }
    }
  });
};
const wrap = ref<HTMLElement>();

watch(
  () => props.modelValue,
  () => {
    tempValue.value = props.modelValue;
    if (!props.modelValue && wrap.value) {
      wrap.value.dataset.value = props.modelValue;
      setHeight();
    }
  },
  { deep: true, immediate: true }
);

defineExpose({
  textArea,
});

onMounted(() => {
  if (props.autoResize && wrap.value) {
    wrap.value.dataset.value = props.modelValue;
  }
});

const onInput = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (wrap.value) {
    wrap.value.dataset.value = target.value;
  }
  tempValue.value = target.value;
  if (!props.emitBlurAsModelValue) {
    const newValue = sanitizeValueForExchangeWords(props.exchangeWords, target.value, props.modelValue);
    emit('update:modelValue', newValue);
  }
  setHeight();
};

onMounted(async () => {
  if (props.setFocus || props.autoFocus) {
    focusOnField(textArea.value);
  }
});

watch(
  () => props.setFocus,
  () => {
    if (props.setFocus && props.watchForFocusChange) {
      focusOnField(textArea.value);
    }
  }
);

const addWordToContent = (word) => {
  const content = addWordToContentOfField(word, props.modelValue, textArea.value);
  emit('update:modelValue', content);
};

const onEnterClick = (e: KeyboardEvent) => {
  // if shift + enter is clicked we want to add a new line
  if (e.shiftKey || props.newLineOnEnter) return;

  if (currentDataListOption.value !== null) {
    selectDataListOption(currentDataListOption.value);
  }

  // if enter is clicked we want to emit the event
  e.preventDefault();
  if (textArea.value) {
    textArea.value.blur();
  }
};

const clickedOnField = (event) => {
  emit('focus', event.target.value);
  inFocus.value = true;

  nextTick(() => {
    if (textArea.value) {
      textArea.value.scrollIntoView({
        block: 'nearest', // This keeps the vertical scroll position unchanged
      });
    }
  });
};

onMounted(() => {
  setHeight();
  setTimeout(() => {
    setHeight();
  }, 50);
  window.addEventListener('resize', setHeight);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', setHeight);
});

const selectDataListOption = (newValue) => {
  emit(props.emitBlurAsModelValue ? 'update:modelValue' : 'blur', newValue);
  inFocus.value = false;
  tempValue.value = newValue;
  blurActiveElement();
};

const focusedBySomeone = whisperForArea(props.whisper, inFocus);

const clickedOutsideOfField = (event) => {
  if (!inFocus.value) return;
  inFocus.value = false;
  emit(props.emitBlurAsModelValue ? 'update:modelValue' : 'blur', event.target.value);
  inFocus.value = false;
  nextTick(() => {
    emit('dataListSelected', event.target.value);
  });
};
</script>

<template>
  <div :class="wrapperClass + (inFocus ? ' text ' : '')">
    <!--  <div :class="{ wrapperClass, 'text': inFocus }">-->
    <InputLabel
      :label="label"
      :title="labelTitle"
      :is-hidden="isHidden"
      :mandatory-text="required ? 'required' : null" />
    <div
      ref="wrap"
      :class="isHidden ? '' : ' bg-inputs-background '"
      class="textarea-wrapper">
      <div
        v-if="iconLeft"
        :class="inFocus ? ' text ' : ' text-soft '"
        class="absolute inline-flex h-full w-[32px] items-center justify-center">
        <i
          class="fa fa-fw"
          :class="iconLeft" />
      </div>
      <textarea
        ref="textArea"
        :tabindex="tabindex"
        :value="tempValue"
        :disabled="!canEdit || focusedBySomeone"
        :style="'min-height:' + minHeight + 'px; resize: none; overflow: hidden'"
        :class="[
          { 'border-transparent disabled:!bg-transparent hover:border-[--color-border-input-hover]': isHidden },
          {
            'border-transparent disabled:bg-[hsl(var(--color-background-input-disabled))] text-disabled  disabled:hover:!border-transparent':
              !canEdit,
          },
          { '!pl-edge-2x': iconLeft },
          square || inTable ? 'rounded-none' : 'rounded',
          inTable
            ? 'bg-transparent outline focus:border-none outline-1 focus:outline-1 focus:outline-offset-0 outline-transparent hover:outline-[--color-border-input-hover] focus:outline-[--color-border-input-focus] border-none m-[2px]'
            : 'rounded  bg-[hsl(var(--color-background-input))] border border-base enabled:focus:hover:border-[--color-border-input-focus] focus:border-[--color-border-input-focus] enabled:hover:border-[--color-border-input-hover]',
        ]"
        class="disabled:cursor-not-allowed!font-normal"
        :placeholder="placeholder"
        :rows="minRows"
        :autofocus="autoFocus"
        @keydown.enter="onEnterClick"
        @keydown.esc="emit('keydown-esc', $event)"
        @focus="clickedOnField($event)"
        @input="onInput"
        @blur="clickedOutsideOfField($event)" />
      <DataList
        v-model:current-data-list-option="currentDataListOption"
        :in-focus="inFocus"
        :model-value="tempValue"
        :data-list-options="dataListOptions"
        :container="textArea"
        @update:model-value="selectDataListOption" />
      <WhisperIndicator
        v-if="focusedBySomeone !== null"
        :whisper="whisper"
        class="bottom-0"
        :name="focusedBySomeone?.name" />
    </div>
    <WordExchanger
      v-if="exchangeWords.length > 0"
      :items="exchangeWords"
      @add-word="addWordToContent" />
    <InputErrorText
      v-if="!!errorMessage"
      :text="errorMessage" />
  </div>
</template>

<style>
.textarea-wrapper {
  display: inline-grid;
  vertical-align: top;
  position: relative;
  align-items: stretch;
  width: 100%;
  min-height: 38px;
}

.textarea-wrapper::after,
.textarea-wrapper > textarea {
  width: auto;
  min-width: 1em;
  font: inherit;
  padding: theme('padding.edge-1/2');
  margin: 0;
  resize: none;
  background: none;
  appearance: none;
  grid-area: 2 / 1;
}

.textarea-wrapper > textarea {
  overflow: hidden;
}

.textarea-wrapper::after {
  content: attr(data-value) ' ';
  visibility: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
