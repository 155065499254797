<script lang="ts" setup>
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { slotEmpty } from '@/util/globals';
import { updateQueryString } from '@/util/query-helpers';
import InfoIcon from '@/components/Icons/InfoIcon.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import DisplayRichText from '@/components/Display/DisplayRichText.vue';
import ActionButtonGroup, { type ActionButtonProps } from '@/components/Inputs/Components/ActionButtonGroup.vue';

type Props = {
  loading?: boolean;
  editMode?: boolean;
  withBackButton?: boolean;
  title?: string | null;
  titleInfo?: string;
  superHeader?: string | null;
  preIcon?: string | null;
  underTitle?: string | null;
  actions?: ActionButtonProps[];
  actionsAsButtons?: boolean | null;
  isPageHeader?: boolean;
  alignCenter?: boolean;
  teleportId?: string;
  headerSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

withDefaults(defineProps<Props>(), {
  loading: false,
  editMode: null,
  withBackButton: false,
  isPageHeader: false,
  title: null,
  titleInfo: '',
  superHeader: null,
  preIcon: null,
  underTitle: null,
  actions: () => [],
  actionsAsButtons: true,
  alignCenter: false,
  teleportId: undefined,
  headerSize: 'h2',
});

const emit = defineEmits<{
  (event: 'back'): void;
  (event: 'edit'): void;
}>();

const smallScreen = useSmallScreen().isSmallScreen;

const goBack = (event = null) => {
  emit('back', event);
  updateQueryString(null, false);
};
</script>

<template>
  <Teleport
    :disabled="!teleportId"
    :to="teleportId">
    <div
      v-if="(!smallScreen || !isPageHeader) && (title || $slots.title)"
      class="relative flex min-h-[3rem] items-center gap-edge-1/2 md:min-h-[4rem] flex-wrap bg-[--color-background-content-header-primary]"
      :class="[withBackButton || preIcon ? 'pr-edge' : 'px-edge', { 'border-b': !underTitle && !$slots.underTitle }]">
      <div
        v-if="withBackButton || preIcon"
        class="h-[4rem] items-center p-edge-1/2 w-[50px]"
        @click="goBack">
        <div
          class="w-full h-full flex items-center rounded justify-center"
          :class="{
            'cursor-pointer hover:bg-row-hover ': withBackButton,
          }">
          <IconWithLoading
            v-if="withBackButton || preIcon"
            classes="text-2xl"
            :icon="withBackButton ? 'fa-arrow-left ' : preIcon" />
        </div>
      </div>

      <div class="flex md:h-[4rem] flex-1 items-center gap-edge-1/4 md:overflow-hidden pr-edge-1/4">
        <div class="flex-0 flex flex-col justify-center overflow-hidden">
          <div
            v-if="superHeader"
            class="sub-title text-soft">
            {{ superHeader }}
          </div>
          <div
            class="flex gap-edge-1/4"
            :class="alignCenter ? 'items-center' : 'items-baseline'">
            <component
              :is="headerSize"
              class="truncate font-headers"
              :title="titleInfo ? titleInfo : title">
              <slot name="title"> {{ title }}</slot>
              <InfoIcon
                v-if="titleInfo"
                class="ml-edge"
                :title="titleInfo" />
            </component>
            <IconWithLoading
              v-if="loading"
              loading />
            <slot name="afterTitle" />
          </div>
        </div>
        <slot name="menuButton"></slot>
      </div>

      <div>
        <ActionButtonGroup
          v-if="actionsAsButtons"
          :actions="
            [
              editMode && editMode !== null
                ? {
                    icon: 'fa-pencil',
                    title: 'Edit',
                    emphasized: true,
                    action: () => {
                      $emit('edit');
                    },
                  }
                : null,
            ]
              .concat(actions)
              .filter((i) => i !== null)
          " />
      </div>
    </div>
    <div
      v-if="(underTitle && underTitle !== ' ') || !slotEmpty($slots, 'underTitle')"
      class="bg-[--color-background-content-header-primary] shadow px-edge pb-edge-1/2 border-b content-header-under-title-container">
      <DisplayRichText
        v-if="underTitle"
        class="!p-0"
        :links-in-new-tab="false"
        :content="underTitle" />
      <slot name="underTitle" />
    </div>
  </Teleport>
</template>
