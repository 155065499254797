<script setup lang="ts">
import { ZIndexPopUpModal } from '@/variables/z-indexes';
import { useSmallScreen } from '@/composables/use-small-screen';
import { useToast } from 'vue-toastification';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { ref } from 'vue';

const { image = null, canEdit = false } = defineProps<{
  image?: any;
  canEdit?: boolean;
}>();

const emit = defineEmits<{
  close: [];
  edit: [];
}>();

const toast = useToast();
const { isSmallScreen } = useSmallScreen();

const isOpen = ref(true);
const loading = ref(false);

const edit = () => {
  emit('edit');
  isOpen.value = false;
};

const closeModal = () => {
  emit('close');
  isOpen.value = false;
};

const downloadImage = async () => {
  if (isSmallScreen.value) {
    window.open(this.image.download_url);
    return;
  }

  loading.value = true;

  try {
    const response = await axios({
      url: this.image.download_url,
      method: 'GET',
      responseType: 'blob', // important
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    const splitName = this.image.filename.split('.');
    if (splitName[splitName.length - 1] === image.extension) {
      link.setAttribute('download', image.filename);
    } else {
      link.setAttribute('download', `${image.filename}.${image.extension}`);
    }

    document.body.appendChild(link);
    link.click();
    loading.value = false;
  } catch (e) {
    console.error(e);
    loading.value = false;
    toast.warning('Something went wrong, please try again later');
  }
};
</script>

<template>
  <teleport to="body">
    <div
      v-if="isOpen"
      :style="'z-index: ' + ZIndexPopUpModal + ';'"
      class="fixed top-0 left-0 flex h-full w-full flex-col bg">
      <div class="c flex divide-x border-b">
        <div class="flex-1 px-edge py-edge-1/4">
          <h3>{{ image.filename }}</h3>
          <small
            v-if="image.user"
            class="uppercase">
            {{ image.user.name }} {{ image.created_at }}
          </small>
        </div>
        <div
          class="hover:bg-tertiary flex w-[64px] cursor-pointer items-center justify-center p-edge uppercase"
          @click="downloadImage">
          <IconWithLoading
            :loading="loading"
            icon="fa-download" />
        </div>
        <div
          v-if="canEdit"
          class="hover:bg-tertiary cursor-pointer p-edge text-center uppercase"
          @click="edit">
          <i class="fa fa-fw fa-pen" /> Edit
        </div>
        <div
          class="hover:bg-tertiary flex w-[64px] cursor-pointer items-center p-edge text-center"
          @click="closeModal">
          <i class="fa fa-fw fa-times m-auto" />
        </div>
      </div>
      <div class="flex h-full items-center p-edge">
        <img
          class="min-h-1 min-w-1 m-auto max-h-full max-w-full"
          :src="image.download_url" />
      </div>
    </div>
  </teleport>
</template>
