<script setup lang="ts">
import { maxUploadSizeKey } from '@/provide/keys';
import { getDocument } from '@/services/api-documents';
import { DocumentResource } from '@/types/document';
import { computed, inject, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import DocumentUploader from '@/components/Documents/DocumentUploader.vue';
import VButton from '@/components/Inputs/VButton.vue';
import ImageViewModal from '@/components/Documents/ImageViewModal.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import { getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import DocumentSelectOrUploadModal from '@/components/Documents/DocumentSelectOrUploadModal.vue';

type Props = {
  modelValue: number | null | string | object;
  model?: string | null;
  modelId?: number | null;
  isTemplate?: boolean;
  isReview?: boolean;
  canEdit?: boolean;
  canEditImage?: boolean;
  documents?: any[];
  emitDocumentActionAs?: string;
};

const props = withDefaults(defineProps<Props>(), {
  model: null,
  modelId: null,
  canEditImage: true,
  isTemplate: false,
  isReview: false,
  canEdit: false,
  emitDocumentActionAs: 'blur',
  documents: () => [],
  'document-uploaded': () => {},
});

const emit = defineEmits<{
  (e: 'blur', value: string | null): void;
  (e: 'documentUploaded', value: string | null): void;
  (e: 'document-uploaded', value: string | null): void;
}>();

const maxMBSize = inject(maxUploadSizeKey, 100);
const loading = ref(true);
const document = ref<DocumentResource | null>(null);

const fetchDocument = async () => {
  if (props.modelValue) {
    if (getKey(props.modelValue, 'uuid')) {
      document.value = {
        uuid: getKey(props.modelValue, 'uuid'),
        mime_type: getKey(props.modelValue, 'mime_type'),
        filename: getKey(props.modelValue, 'title'),
      };
    } else {
      if (props.documents) {
        const doc = getItemFromArrayBasedOnId(Number(props.modelValue), props.documents);
        if (doc) {
          document.value = doc;
          loading.value = false;
          return;
        }
      }
      const { data } = await getDocument(props.modelValue);
      document.value = data;
    }
  }
  loading.value = false;
};

switch (props.model) {
  case 'Event': {
    setTimeout(() => {
      fetchDocument();
    }, 5000);
    break;
  }
  default: {
    setTimeout(() => {
      fetchDocument();
    }, 500);
  }
}

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      fetchDocument();
    } else {
      document.value = null;
    }
  }
);
watch(
  () => props.documents,
  () => {
    if (props.modelValue) {
      fetchDocument();
    }
  }
);

const isImage = computed(() => {
  if (!document.value) return false;
  return document.value.mime_type.startsWith('image');
});

const showImage = ref(false);

const removeDocument = async () => {
  if (props.model === 'MetaData') {
    const res = await useCertaintyModal().assertCertain(
      ' Remove Document',
      'Are you sure you want to remove this document? if so, you will delete  it'
    );
    if (!res) return;
    await axios.delete(`/api/documents/${document.value.id}`);
    emit('blur', null);
    useToast().success('Document removed');
  } else {
    const res = await useCertaintyModal().assertCertain(
      ' Remove Document',
      'Are you sure you want to remove this document?'
    );
    if (res) {
      emit('blur', null);
    }
  }
  document.value = null;
};

const documentAdded = (doc) => {
  emit('blur', doc.id);
  document.value = doc;
  modalOpen.value = false;
};
const fileClicked = () => {
  if (!document.value) return;
  if (isImage.value) {
    showImage.value = false;
    nextTick(() => {
      showImage.value = true;
    });
  } else {
    if (getKey(document.value, 'download_url')) {
      window.open(document.value.download_url);
    } else if (getKey(document.value, 'uuid')) {
      window.open('https://ucarecdn.com/' + document.value.uuid + '/');
    }
  }
};

const modalOpen = ref(false);
</script>

<template>
  <div
    v-if="document"
    class="flex h-[38px] items-center justify-between gap-1 overflow-hidden rounded border px-edge">
    <div
      class="truncate pl-1"
      :title="document.filename"
      :class="{ 'cursor-pointer': isImage }"
      @click="fileClicked()">
      <i
        class="fa fa-fw"
        :class="isImage ? 'fa-picture-o' : 'fa-file'" />
      {{ document.filename }}
    </div>
    <div v-if="canEdit">
      <VButton
        icon="fa-times"
        size="xs"
        classes="border-t-0 border-b-0 border-l border-r-0 rounded-l-none"
        @click="removeDocument" />
    </div>
    <ImageViewModal
      v-if="isImage && showImage"
      :image="{
        ...document,
        download_url: document.download_url ? document.download_url : 'https://ucarecdn.com/' + document.uuid + '/',
      }"
      :can-edit="canEdit && canEditImage"
      @close="showImage = false" />
  </div>
  <div
    v-else-if="isReview && !document"
    class="h-full w-full">
    <div class="text-xs italic text-soft">No Document Uploaded.</div>
  </div>
  <div
    v-else
    class="h-[38px] w-full">
    <div
      v-if="!isReview && !['Performance', 'Event'].includes(model)"
      class="h-[38px] max-w-[250px]">
      <DocumentUploader
        :can-edit="canEdit && !isTemplate"
        :model-id="modelId"
        :max-m-b-size="maxMBSize"
        :working-outside="loading"
        :model="model"
        @document-uploaded="documentAdded" />
    </div>
    <div v-else-if="!isReview && ['Performance', 'Event'].includes(model)">
      <VButton
        size="lg"
        icon="fa-upload fa-regular"
        title="Upload File"
        emphasized
        class="w-full"
        :loading="loading && canEdit"
        :disabled="loading || !canEdit"
        tool-tip-text="Select or Upload document to this cell"
        @click="
          [
            (modalOpen = false),
            nextTick(() => {
              modalOpen = true;
            }),
          ]
        " />
    </div>

    <DocumentSelectOrUploadModal
      v-if="modalOpen && ['Performance', 'Event'].includes(model)"
      :selected-document-id="modelValue"
      :model="model"
      :model-id="modelId"
      @document:selected="documentAdded($event)" />
  </div>
</template>
