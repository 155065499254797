<script lang="ts" setup>
import { nextTick, ref, watch } from 'vue';
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import { getKey } from '@/util/globals.js';
import VButton from '@/components/Inputs/VButton.vue';
import { formatStampAsDateTime, formatStampAsTime, getDiffInInterval } from '@/util/timeFunctions';
import BoxContainer from '@/components/Elements/BoxContainer.vue';

type Props = {
  showTime: any;
  canEdit?: boolean;
  setFocusOnStart?: boolean;
  rooms?: [];
  canSelectRoom?: boolean;
  editMode?: boolean;
  small?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  canEdit: true,
  rooms: () => [],
  canSelectRoom: true,
  editMode: false,
  small: false,
  setFocusOnStart: false,
});

const emit = defineEmits<{
  (event: 'delete'): void;
  (event: 'save', showTime: any): void;
  (event: 'update:showTime', showTime: any): void;
  (event: 'roomUpdated', value: object): void;
}>();

const localShowTime = ref({
  id: getKey(props.showTime, 'id'),
  start: getKey(props.showTime, 'start'),
  end: getKey(props.showTime, 'end'),
  room_id: getKey(props.showTime, 'room_id', null),
  title: getKey(props.showTime, 'title'),
});

watch(
  () => props.showTime,
  () => {
    localShowTime.value.start = getKey(props.showTime, 'start', localShowTime.value.start);
    localShowTime.value.end = getKey(props.showTime, 'end', localShowTime.value.end);
    localShowTime.value.room_id = getKey(props.showTime, 'room_id', localShowTime.value.room_id);
  },
  { deep: true }
);

const removeShowTime = () => {
  emit('delete');
};

const emitBlur = () => {
  if (!props.canEdit) return;
  emit('update:showTime', localShowTime.value);
};

const save = () => {
  if (!props.canEdit) return;
  emit('save', localShowTime.value);
};

const formattedShowTime = () => {
  let string = '';
  const dur = getDiffInInterval(localShowTime.value.start, localShowTime.value.end, 'minutes');
  if (dur < 60 * 24) {
    if (dur < 60) {
      string = `${formatStampAsDateTime(localShowTime.value.start, 'dddd MMMM Do [,] HH:mm')} - ${formatStampAsTime(
        localShowTime.value.end
      )} (${dur} minutes)`;
    } else {
      const hours = Math.floor(dur / 60);
      const minutes = dur - 60 * hours;
      string = `${formatStampAsDateTime(localShowTime.value.start, 'dddd MMMM Do [,] HH:mm')} - ${formatStampAsTime(
        localShowTime.value.end
      )} (${hours} hours ${minutes > 0 ? `and ${minutes} minutes` : ''})`;
    }
  } else {
    string = `${formatStampAsDateTime(localShowTime.value.start, 'ddd MMM Do [at] HH:mm')} - ${formatStampAsDateTime(
      localShowTime.value.end,
      'ddd MMM Do [at] HH:mm'
    )} (${dur / 60} hours)`;
  }
  return `${string}`;
};

const currentlyNothingInFocus = () => {
  if (!props.setFocusOnStart) return;
  switch (document.activeElement.tagName) {
    case 'BUTTON': {
      return true;
    }
    default: {
      return false;
    }
  }
};

const roomUpdated = async (newRoomId?: number | null = null) => {
  emit('roomUpdated', {
    oldId: localShowTime.value.room_id,
    newId: newRoomId,
  });
  await nextTick();
  localShowTime.value.room_id = newRoomId;
  emitBlur();
};
</script>

<template>
  <BoxContainer>
    <div class="md:form-layout [&>div>div]:min-h-[60px]">
      <StartEndPicker
        v-model:start="localShowTime.start"
        v-model:end="localShowTime.end"
        :vertical="small"
        with-time
        :can-edit="canEdit"
        :set-focus="currentlyNothingInFocus()"
        with-duration
        :allow-no-duration="false"
        required
        :duration-options="[30, 60, 90, 120, 180, 240, 360, 480, 600, 720, 840]"
        @update:start="emitBlur"
        @update:end="emitBlur" />
      <div class="grid gap-edge mt-edge justify-between grid-cols-[1fr_1fr_70px]">
        <TextInput
          v-model="localShowTime.title"
          title="Title"
          label="Title"
          @blur="emitBlur" />
        <VSelect
          :model-value="getKey(localShowTime, 'room_id', null)"
          :can-edit="canSelectRoom && rooms.length > 0"
          nullable
          nullable-display-text="-- N/A --"
          label="Room"
          :options="rooms"
          @update:model-value="roomUpdated" />
        <VButton
          icon="fa-trash"
          type="warning"
          class="mt-[30px]"
          title="Delete"
          size="xs"
          @click="removeShowTime" />
      </div>
    </div>

    <!--    <div-->
    <!--      v-if="!editMode"-->
    <!--      class="flex items-center justify-between gap-1 show-time-editor-display-container">-->
    <!--      <div class="flex gap-edge">-->
    <!--        <div-->
    <!--          v-if="showTime.title"-->
    <!--          class="text-highlight">-->
    <!--          <InputLabel-->
    <!--            label="Title"-->
    <!--            super-text />-->
    <!--          {{ localShowTime.title }}-->
    <!--        </div>-->
    <!--        <div>-->
    <!--          <InputLabel-->
    <!--            label="When"-->
    <!--            super-text />-->
    <!--          {{ formattedShowTime() }}-->
    <!--        </div>-->
    <!--        <div-->
    <!--          v-if="localShowTime.room_id && rooms.length"-->
    <!--          class="text-highlight">-->
    <!--          <InputLabel-->
    <!--            label="Location"-->
    <!--            super-text />-->
    <!--          {{ getItemFromArrayBasedOnId(localShowTime.room_id, rooms, { name: '' }).name }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div-->
    <!--        v-if="canEdit"-->
    <!--        class="button-container-for-show-time-editor">-->
    <!--        <VButton-->
    <!--          type="inTable"-->
    <!--          icon="fa-pencil"-->
    <!--          title="Edit"-->
    <!--          @click="editMode = true"></VButton>-->
    <!--      </div>-->
    <!--    </div>-->
  </BoxContainer>
</template>
