<script lang="ts" setup>
import ContentHeader from '@/components/Content/ContentHeader.vue';
import VTable from '@/components/Tables/VTable.vue';
import Paginator from '@/components/Tables/Paginator.vue';
import { createUuId, slotEmpty } from '@/util/globals';
import { computed, inject, nextTick, onMounted, useTemplateRef } from 'vue';
import EmptyStateFullPage from '@/components/EmptyState/EmptyStateFullPage.vue';
import ActionButtonGroup, { ActionButtonProps } from '@/components/Inputs/Components/ActionButtonGroup.vue';
import { contentListElement } from '@/provide/keys';

type PaginatorResource = {
  perPage: number;
  total: number;
  pages: number;
  page: number;
  action: (page: number) => void;
};

type Props = {
  actions?: ActionButtonProps[];
  title?: string;
  superHeader?: string;
  loading?: boolean;
  paginatorData?: PaginatorResource;
  emptyAction?: null;
  emptyMessage?: string | null;
  emptyIcon?: string | null;
  stickyFirstColumn?: boolean;
  bordersOnTable?: boolean;
  snapRows?: boolean;
  firstLoad?: boolean;
  isPageHeader?: boolean;
  tableClasses?: string | null;
  backAction?: (() => void) | null;
  actionsAsButtons?: boolean | null;
  teleport?: boolean;
};

const {
  actions = [],
  title = undefined,
  superHeader = undefined,
  loading = false,
  isPageHeader = false,
  snapRows = true,
  paginatorData = undefined,
  emptyAction = null,
  emptyMessage = null,
  emptyIcon = null,
  backAction = null,
  tableClasses = null,
  actionsAsButtons = true,
  bordersOnTable = true,
  firstLoad = true,
  teleport = true,
} = defineProps<Props>();

const emit = defineEmits<{
  backClicked: [arg: unknown];
}>();

const injectElement = inject(contentListElement, 'main');

const container = useTemplateRef<HTMLDivElement>('container');

const updateSize = () => {
  const screenHeight = window.innerHeight;

  const main = document.querySelector('main');

  const firstHeight = screenHeight - main?.getBoundingClientRect().top;

  const wrapperElem = document.querySelector(injectElement);

  if (wrapperElem && container.value) {
    let totalHeight: number;

    if (injectElement === 'main') {
      totalHeight = firstHeight;
    } else {
      totalHeight = screenHeight > wrapperElem.offsetHeight ? wrapperElem.offsetHeight : firstHeight;
    }
    container.value.style.height = `${totalHeight}px`;
  }
};

onMounted(async () => {
  await nextTick();
  updateSize();
  setTimeout(() => {
    updateSize();
  }, 50);
  setTimeout(() => {
    updateSize();
  }, 250);
});

const uuid = createUuId('header_');

const slot = defineSlots<{
  title?: () => never;
  afterTitle?: () => never;
  underTitle?: () => never;
  searches?: () => never;
  overrideContent?: () => never;
  head?: () => never;
  bottomLeft?: () => never;
  default?: () => never;
}>();

const hasTitle = computed(() => {
  return title || slot?.afterTitle !== undefined || slot?.underTitle !== undefined;
});
</script>

<template>
  <div
    ref="container"
    class="flex flex-col overflow-hidden">
    <div
      :id="uuid"
      key="header"
      class="content-table-header bg">
      <slot name="title" />

      <ContentHeader
        v-if="hasTitle"
        :loading="loading"
        :super-header="superHeader"
        :title="title"
        :is-page-header="isPageHeader"
        :actions="actions"
        :actions-as-buttons="actionsAsButtons"
        :with-back-button="backAction !== null"
        @back="[backAction ? backAction($event) : null, emit('backClicked')]">
        <template #afterTitle>
          <slot name="afterTitle" />
        </template>
        <template #underTitle>
          <slot name="underTitle" />
        </template>
      </ContentHeader>

      <Teleport
        v-if="actions.length || !slotEmpty($slots, 'searches')"
        defer
        :disabled="!teleport"
        to="#after-navbar-title">
        <div>
          <div>
            <slot name="searches" />
          </div>
          <template v-if="actions.length && !title">
            <ActionButtonGroup
              v-if="actionsAsButtons"
              :actions="actions"
              class="px-edge"
              inline />
          </template>
        </div>
      </Teleport>
    </div>

    <div
      class="overflow-auto flex-1 bg-content-main"
      :class="tableClasses">
      <slot name="overrideContent">
        <VTable
          v-if="!emptyMessage"
          sticky-header
          header-hover
          :snap-rows="snapRows"
          :bordered-table="bordersOnTable"
          :sticky-first-column="stickyFirstColumn"
          row-size="medium"
          edge-to-edge>
          <template #head>
            <slot name="head" />
          </template>
          <slot />
        </VTable>
      </slot>
      <div
        v-if="!firstLoad"
        class="text-5xl text-center text-soft">
        <i class="fa fa-fw fa-circle-o-notch fa-spin" /> <br />
        <span class=""> Loading </span>
      </div>
      <EmptyStateFullPage
        v-if="emptyMessage"
        :icon="emptyIcon"
        :description="emptyMessage"
        :button-text="emptyAction ? 'Create New' : null"
        :button-function="emptyAction" />
    </div>

    <Paginator
      v-show="paginatorData && !emptyMessage"
      class="border-t bg rounded-none h-[42px]"
      :loading="loading"
      :per-page="paginatorData?.perPage"
      :total-count="paginatorData?.total"
      :total-pages="paginatorData?.pages"
      :current-page="paginatorData?.page"
      @change-page="loading ? null : paginatorData?.action($event)">
      <template #before>
        <slot name="bottomLeft" />
      </template>
    </Paginator>
  </div>
</template>
