<script lang="ts" setup>
import { TaskFieldResource, TaskResource } from '@/types/tasks';
import { ref } from 'vue';
import { getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId } from '@/util/globals';
import { getComponent } from '@/util/get-component';
import { allAvailableFieldTypes } from '@/util/fields';
import { postTaskTaskFieldPivot } from '@/services/api-tasks';
import VDropdown from '@/components/Inputs/Dropdown/VDropdown.vue';

type Props = {
  task: TaskResource;
  taskField: TaskFieldResource;
  lockedKeys?: string[];
  showIcon?: boolean;
  inList?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  lockedKeys: () => [],
  showIcon: false,
  inList: false,
});
const emit = defineEmits<{
  (e: 'updated', value: TaskResource): void;
}>();

const selectListItem = async (value: number | string | null) => {
  const item = getItemFromArrayBasedOnId(value, props.taskField.task_field_options);
  if (!item) return;

  await selectValue(item.title, item.id);
};
const selectValue = async (value: number | string | null, taskFieldOptionId: number | null) => {
  const localTask = { ...props.task };
  await postTaskTaskFieldPivot(localTask.uuid, props.taskField.id, value, taskFieldOptionId);

  const index = getIndexFromArrayBasedOnId(props.taskField.id, props.task.task_task_field_pivots, 'task_field_id');
  if (index > -1) {
    localTask.task_task_field_pivots[index].value = value;
    localTask.task_task_field_pivots[index].task_field_option_id = taskFieldOptionId;
  } else {
    localTask.task_task_field_pivots.push({
      id: null,
      task_id: props.task.id,
      task: null,
      task_field_id: props.taskField.id,
      task_field: null,
      task_field_option_id: taskFieldOptionId,
      value: value,
    });
  }
  emit('updated', localTask);
};
const isClicked = ref(false);

const getCurrentValue = (defaultValue?: string | null) => {
  return getItemFromArrayBasedOnId(
    props.taskField.id,
    props.task.task_task_field_pivots,
    { value: defaultValue },
    'task_field_id'
  ).value;
};
const getCurrentSelectedOption = () => {
  if (!props.taskField.component === 'field-list') return null;
  const taskFieldOptionId = getItemFromArrayBasedOnId(
    props.taskField.id,
    props.task.task_task_field_pivots,
    { task_field_option_id: null },
    'task_field_id'
  ).task_field_option_id;
  if (!taskFieldOptionId) {
    return null;
  }
  return getItemFromArrayBasedOnId(taskFieldOptionId, props.taskField.task_field_options);
};

const getProps = () => {
  const defaultProps = {
    canEdit: true,
    isHidden: true,
    'model-value': getCurrentValue(null),
  };

  const leftIcon = props.showIcon
    ? getItemFromArrayBasedOnId(props.taskField.component, [...allAvailableFieldTypes], { icon: null }, 'component')
        .icon
    : null;
  switch (props.taskField.component) {
    case 'field-text': {
      return {
        ...defaultProps,
        'min-height': 32,
        'icon-left': leftIcon,
      };
    }
    case 'field-number': {
      return {
        ...defaultProps,
        withDecimals: true,
        'icon-left': leftIcon,
        'size': 'block',
      };
    }
    case 'field-list': {
      return {
        ...defaultProps,
        options: props.taskField.task_field_options,
        nullable: true,
        'icon-left': leftIcon,
        nullableDisplayText: ' ',
      };
    }
    case 'field-date': {
      return {
        ...defaultProps,
        withIcon: false,
      };
    }
    case 'field-time': {
      return {
        ...defaultProps,
        leftIcon: leftIcon,
      };
    }
    case 'field-rich-text':
    default: {
      return defaultProps;
    }
  }
};

const getClass = () => {
  switch (props.taskField.component) {
    case 'field-text': {
      return '[&_*]:!rounded-none [&_.textarea-wrapper]:min-h-[33px] [&_.textarea-wrapper:after]:p-0 [&_textarea]:px-edge-1/2 pt-1 [&_textarea]:py-edge-1/4 [&_textarea]:text-sm';
    }
    case 'field-toggle': {
      return 'h-[33px] pt-[6px]' + (props.inList ? ' pl-[30px] ' : ' !pl-[11px] ');
    }
    case 'field-date': {
      return '[&_*]:!rounded-none [&_.input-field:hover]:!ring-1 [&_.input-field:hover]:!ring-textColor-soft [&_.input-field]:h-[33px] [&_input]:px-edge-1/2 [&_input]:py-edge-1/4  [&_input]:text-sm';
    }
    case 'field-time': {
      return '[&_*]:!rounded-none [&_input]:px-edge-1/2 [&_input]:py-edge-1/4  [&_input]:text-sm';
    }
    case 'field-number': {
      return '[&_*]:!rounded-none [&_input]:px-edge-1/2 [&_input]:py-edge-1/4 [&>div>*]:!h-[33px] [&_input]:text-sm';
    }
    case 'field-list': {
      return '[&_*]:!rounded-none [&_input]:px-edge-1/4 [&_input]:py-1';
    }
    default: {
      return '[&_*]:!rounded-none [&_input]:px-edge-1/4 [&_input]:py-1';
    }
  }
};

const getOptionsForListField = () => {
  return [
    {
      color: null,
      title: '--',
      action: (close) => {
        selectValue(null, null);
        close();
      },
    },
  ].concat(
    props.taskField.task_field_options.map((o) => {
      return {
        color: o.color,
        title: o.title,
        action: (close) => {
          selectValue(o.title, o.id);
          close();
        },
      };
    })
  );
};
</script>

<template>
  <div
    v-if="taskField.component === 'field-list'"
    :class="{
      '!pl-[11px]': !inList,
    }"
    class="!py-0 !px-edge-1/4 h-[33px] flex items-center">
    <VDropdown
      close-on-click
      :items="getOptionsForListField()"
      :have-max-width="false">
      <template #click-area>
        <button
          :style="'background-color: ' + getCurrentSelectedOption()?.color"
          :class="{
            'hover:!text-[hsl(var(--gray-950))] text-[hsl(var(--gray-950))] ': getCurrentSelectedOption() !== null,
            'hover:!bg-row-alternate': inList && getCurrentSelectedOption() === null,
            '!brightness-50 ': inList && task.completed_at && getCurrentSelectedOption() !== null,
            '  min-w-[166px] max-w-[166px] ': inList,
            '  min-w-[226px] max-w-[226px] ': !inList,
          }"
          :title="getCurrentValue()"
          class="btn btn-tiny grid grid-cols-[auto_10px] justify-between !ring-transparent border-transparent truncate py-[2px] px-edge-1/2 w-full items-center gap-1">
          <span class="min-h-[18px] truncate">
            {{ getCurrentValue('--') }}
          </span>
          <i class="fa fa-fw text-xxs fa-chevron-down"></i>
        </button>
      </template>
      <template #pre="{ item }">
        <i
          v-if="item.color"
          class="fa fa-fw fa-circle"
          :style="'color: ' + item.color" />
      </template>
    </VDropdown>
  </div>
  <div
    v-else
    :class="getClass()">
    <component
      :is="getComponent(taskField.component)"
      v-bind="getProps()"
      @blur="selectValue($event)" />
  </div>
</template>
