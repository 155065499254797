'
<script setup lang="ts">
import { InviteResource } from '@/types/invite';
import { computed, defineAsyncComponent, nextTick, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import VButton from '@/components/Inputs/VButton.vue';
import SearchSelectFloatingWindow from '@/components/Inputs/Components/SearchSelectFloatingWindow.vue';
import PartnerContactCreateModal from '@/components/Partners/PartnerContactCreateModal.vue';
import PartnerCompanyCreateModal from '@/components/Partners/PartnerCompanyCreateModal.vue';
import AddPartnersConnectedToPartnerModal from '@/components/AddPartnersConnectedToPartnerModal.vue';
import { useSmallScreen } from '@/composables/use-small-screen';
import { exchangeValuesOfObject, getKey } from '@/util/globals';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { concatName } from '@/services/api-partners';
import { useMultipleButtonsModal } from '@/composables/modals/use-multiple-buttons-modal';
import { PartnerCompanyResource, PartnerContactResource } from '@/types/partners';
import { eventInvite } from '@/services/api-invite';
import ConnectPartnerCompanyToGroupModal from '@/components/Partners/ConnectPartnerCompanyToGroupModal.vue';

type Props = {
  invite: InviteResource;
  canEdit: boolean;
  show: boolean;
  groupId: number;
  onEventPage: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:show', value: boolean): void;
}>();

const PartnerSlideOut = defineAsyncComponent(() => import('@/components/Modals/PartnerSlideOut.vue'));

const toast = useToast();
const { assertCertain } = useCertaintyModal();

const loading = ref(false);
const modalOpen = ref(false);
const hasLoaded = ref(true);
const contactModalOpen = ref(false);
const partnerCompanies = ref<PartnerCompanyResource[]>(props.invite.partner_companies);
const partnerContacts = ref<PartnerContactResource[]>(props.invite.partner_contacts);

const partners = ref(null);
const selectedContact = ref(null);
const selectedCompany = ref(null);
const connectPartnerCompanyToGroupCompany = ref(null);
const connectPartnerCompanyToGroupCompanyHasWriteAccess = ref(false);
const { isSmallScreen } = useSmallScreen();

const sortedContacts = computed(() => {
  return partnerContacts.value.slice().sort((a, b) => a.first_name - b.first_name);
});

const sortedCompanies = computed(() => {
  return partnerCompanies.value.sort((a, b) => a.partner_type_id - b.partner_type_id).sort((a, b) => a.name - b.name);
});

watch(
  () => props.show,
  () => {
    contactModalOpen.value = false;
    selectedContact.value = null;
  }
);

const getInvite = async () => {
  if (hasLoaded.value) return;
  loading.value = true;
  const { data } = await axios.get(`/api/invites/${props.invite.id}/partners`);

  partnerContacts.value = data.contacts;
  partnerCompanies.value = data.companies;
  loading.value = false;
  hasLoaded.value = true;
};

const detachCompany = async (company) => {
  if (!props.canEdit) return;
  const c = await assertCertain(
    'Remove Company',
    `Are you sure you want to remove ${company.name} from ${props.invite.event.name} ?`
  );
  if (!c) return;
  await axios.post(`/api/partners/companies/${company.id}/invites/${props.invite.id}/detach`);
  const index = partnerCompanies.value.indexOf(company);
  if (index > -1) {
    toast.success(`${company.name} removed`);
    partnerCompanies.value.splice(index, 1);
  }
};

const detachContact = async (contact) => {
  if (!props.canEdit) return;
  const c = await assertCertain(
    'Remove Contact',
    `Are you sure you want to remove ${contact.first_name} ${contact.last_name} from ${props.invite.event.name} ?`
  );
  if (!c) return;
  await axios.post(`/api/partners/contacts/${contact.id}/invites/${props.invite.id}/detach`);
  const index = partnersContacts.value.indexOf(contact);
  if (index > -1) {
    toast.success(`${contact.first_name} ${contact.last_name} removed`);
    partnersContacts.value.splice(index, 1);
  }
};

const showModalFor = (contact) => {
  contactModalOpen.value = false;
  selectedContact.value = contact;
  nextTick(() => {
    contactModalOpen.value = true;
  });
};

const searchForCompany = ref(false);
const searchForContact = ref(false);
const pageY = ref(null);
const pageX = ref(null);
const openModal = (event, newSearchForCompany = true) => {
  searchForCompany.value = false;
  searchForContact.value = false;
  pageY.value = null;
  pageX.value = null;
  nextTick(() => {
    pageX.value = event.target.getBoundingClientRect().x - (newSearchForCompany ? 300 : 300);
    pageY.value = event.target.getBoundingClientRect().y + 38;
    searchForCompany.value = newSearchForCompany;
    searchForContact.value = !newSearchForCompany;
  });
};
const createCompanyName = ref(null);
const showCreatePartnerSlideOut = ref(false);

const createCompany = (companyName) => {
  createCompanyName.value = companyName;
  showCreatePartnerSlideOut.value = false;
  nextTick(() => {
    showCreatePartnerSlideOut.value = true;
  });
};
const newPartnerContactName = ref('');
const showPartnerContactModal = ref(false);
const createPartnerContact = (name: string) => {
  newPartnerContactName.value = name;
  showPartnerContactModal.value = false;
  nextTick(() => {
    showPartnerContactModal.value = true;
  });
};

const addCompany = async (company) => {
  await axios.post(`/api/partners/companies/${company.id}/invites/${props.invite.id}/attach`);
  toast.success(`${company.name} added`);

  partnerCompanies.value.push({
    name: company.name,
    id: company.id,
    partner_type_id: company.partner_type_id,
  });

  const invite = await useMultipleButtonsModal().threeButtonModal({
    title: 'Invite ' + company.name,
    description: 'Would you like to invite ' + company.name + ' to ' + props.invite.event.name + '?',
    button: {
      text: 'Invite',
      type: 'success',
    },
    options: [
      {
        value: 'first',
        label: 'With Write Access',
      },
      {
        value: 'second',
        label: 'Without Write Access',
      },
    ],
  });
  if (invite === 'cancel') {
    await checkIfAnyContactsShouldBeInvited(company);
    return;
  }

  if (company.group_id) {
    await eventInvite(props.invite.event.id, {
      group_id: company.group_id,
      project_leader_id: null,
      write: invite === 'first',
      skipProjectLeader: true,
    });
    await checkIfAnyContactsShouldBeInvited(company);
  } else {
    connectPartnerCompanyToGroupCompanyHasWriteAccess.value = invite === 'first';
    connectPartnerCompanyToGroupCompany.value = null;
    await nextTick();
    connectPartnerCompanyToGroupCompany.value = company;
  }
};
const partnerCompanyConnectedToGroup = async (company: PartnerCompanyResource) => {
  await eventInvite(props.invite.event.id, {
    group_id: company.group_id,
    project_leader_id: null,
    write: connectPartnerCompanyToGroupCompanyHasWriteAccess.value,
    skipProjectLeader: true,
  });
  await checkIfAnyContactsShouldBeInvited(company);
  connectPartnerCompanyToGroupCompany.value = null;
  connectPartnerCompanyToGroupCompanyHasWriteAccess.value = false;
};
const checkIfAnyContactsShouldBeInvited = async (company: PartnerCompanyResource) => {
  const { data } = await axios.get(`/api/partners/companies/${company.id}/contacts/`);
  if (data.length === 0) {
    return;
  }
  selectedCompany.value = Object.assign(company, {
    contacts: data,
  });
};

const addContact = async (c) => {
  if (partnerContacts.value.map((e) => e.id).includes(c.id)) return;
  await axios.post(`/api/partners/contacts/${c.id}/invites/${props.invite.id}/attach`);
  partnerContacts.value.push({
    first_name: c.first_name,
    last_name: c.last_name,
    phone: c.phone,
    email: c.email,
    id: c.id,
  });
  toast.success(`${c.name} added`);
  if (c.email) {
    const invite = await useMultipleButtonsModal().threeButtonModal({
      title: 'Invite ' + concatName(c),
      description: 'Would you like to invite ' + c.first_name + ' to ' + props.invite.event.name + '?',
      button: {
        text: 'Invite',
        type: 'success',
      },
      options: [
        {
          value: 'first',
          label: 'With Write Access',
        },
        {
          value: 'second',
          label: 'Without Write Access',
        },
      ],
    });
    if (invite === 'cancel') return;
    await axios
      .post(`/api/events/${props.invite.event.id}/invite-user`, {
        user_id: getKey(c, 'user_id'),
        partner_contact_id: c.id,
        email: c.email,
        write: invite === 'first',
        in_timeline: true,
      })
      .catch((error) => {
        if (error.response.status === 409) {
          useToast().warning(concatName(c) + ' is already invited.');
          return;
        }
        useToast().warning('Something went wrong.');
        return;
      });
    useToast().success('Invited.');
  }
};

const companyActions = [
  {
    icon: 'fa-plus',
    title: 'Add Company',
    action: ($event) => {
      openModal($event, true);
    },
  },
];

const contactActions = [
  {
    icon: 'fa-plus',
    title: 'Add Contact',
    action: ($event) => {
      openModal($event, false);
    },
  },
];

const showPartner = ref(false);
const partnerId = ref<number | null>(null);
const currentType = ref<'company' | 'contact' | null>(null);

const openPartner = (partner: any, type: 'company' | 'contact') => {
  partnerId.value = partner.id;
  currentType.value = type;
  showPartner.value = true;
};

const openOther = (e: { type: 'company' | 'contact'; [key: string] }) => {
  showPartner.value = false;
  currentType.value = e.type;
  setTimeout(() => {
    openPartner(e, e.type);
  }, 200);
};

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <div>
    <BoxContainer
      title="Contacts"
      :header-size="'h3'"
      :content-padding="false"
      :actions="canEdit && !isSmallScreen ? contactActions : []"
      @open="getInvite">
      <div v-if="hasLoaded">
        <VTable
          v-if="sortedContacts.length === 0"
          edge-to-edge>
          <VTableRow no-background>
            <VTableCell>
              <div class="italic text-soft">No Contacts Added</div>
            </VTableCell>
          </VTableRow>
        </VTable>
        <VTable
          v-if="sortedContacts.length > 0"
          edge-to-edge
          :softer-background-header="true"
          :bordered-table="true"
          row-size="small">
          <VTableRow
            v-for="contact in sortedContacts"
            :key="'contact_' + contact.id"
            clickable
            @click="openPartner(contact, 'contact')">
            <VTableCell main-cell>
              <div class="hover:underline cursor-pointer">{{ contact.first_name }} {{ contact.last_name }}</div>
            </VTableCell>

            <VTableCell style="width: 90px">
              <div class="flex justify-between">
                <VButton
                  icon="fa-eye fa-regular"
                  size="sm"
                  @click="openPartner(contact, 'contact')" />
                <VButton
                  v-if="!isSmallScreen && canEdit"
                  size="sm"
                  icon="fa-trash fa-regular"
                  @click="detachContact(contact)" />
              </div>
            </VTableCell>
          </VTableRow>
        </VTable>
      </div>
    </BoxContainer>
  </div>
  <div>
    <BoxContainer
      title="Companies"
      :content-padding="false"
      :header-size="'h3'"
      :actions="canEdit && !isSmallScreen ? companyActions : []"
      @open="getInvite">
      <div v-if="hasLoaded">
        <VTable
          v-if="sortedCompanies.length === 0"
          edge-to-edge>
          <VTableRow no-background>
            <VTableCell>
              <div class="italic text-soft">No Companies Added</div>
            </VTableCell>
          </VTableRow>
        </VTable>
        <VTable
          v-if="sortedCompanies.length > 0"
          edge-to-edge
          :bordered-table="true"
          :softer-background-header="true"
          row-size="small">
          <VTableRow
            v-for="company in sortedCompanies"
            :key="'company_' + company.id">
            <VTableCell
              main-cell
              :title="company.name + (company.partner_type === null ? '' : ' - ' + company.partner_type)">
              <div
                class="hover:underline cursor-pointer"
                @click="openPartner(company, 'company')">
                {{ company.name }}
              </div>
            </VTableCell>

            <VTableCell style="width: 90px">
              <div class="flex justify-between">
                <VButton
                  icon="fa-eye fa-regular"
                  size="sm"
                  @click="openPartner(company, 'company')" />
                <VButton
                  v-if="!isSmallScreen && canEdit"
                  size="sm"
                  icon="fa-trash fa-regular"
                  @click="detachCompany(company)" />
              </div>
            </VTableCell>
          </VTableRow>
        </VTable>
      </div>
    </BoxContainer>
  </div>

  <Suspense>
    <template #default>
      <PartnerSlideOut
        v-if="showPartner && partnerId"
        :partner-type="currentType"
        :partner-id="partnerId"
        :model-id="groupId"
        :model-type="'Group'"
        :can-edit="canEdit"
        :venue-id="null"
        :z-index="onEventPage ? 102 : undefined"
        @update-partner="
          currentType === 'company'
            ? exchangeValuesOfObject($event, partnerCompanies, ['id'], 'id', false)
            : exchangeValuesOfObject($event, partnerContacts, ['id'], 'id', false)
        "
        @deleted="[(showPartner = false), fetchData()]"
        @open-other="openOther"
        @closed="[(showPartner = false)]" />
    </template>

    <template #fallback>
      <div>loading...</div>
    </template>
  </Suspense>

  <SearchSelectFloatingWindow
    v-if="pageX && pageY && searchForCompany"
    url="/api/partners/companies"
    placeholder="Search For Company"
    :debounce-time="500"
    can-create
    :params="{
      model_type: 'App\\Group',
      model_id: groupId,
    }"
    :page-y="pageY"
    :page-x="pageX"
    :already-selected-ids="sortedCompanies.map((item) => item.id)"
    @closed="pageX = null"
    @create="createCompany"
    @selected="addCompany" />

  <PartnerCompanyCreateModal
    v-if="showCreatePartnerSlideOut"
    :init-company="{ name: createCompanyName }"
    :model-id="groupId"
    model="Group"
    :can-add-contacts="false"
    @closed="showCreatePartnerSlideOut = false"
    @saved="addCompany($event)" />

  <SearchSelectFloatingWindow
    v-if="pageX && pageY && searchForContact"
    url="/api/partners/contacts"
    placeholder="Search For Contact"
    :debounce-time="500"
    can-create
    :params="{
      model_type: 'App\\Group',
      model_id: groupId,
    }"
    :page-y="pageY"
    :page-x="pageX"
    :already-selected-ids="sortedContacts.map((item) => item.id)"
    @closed="pageX = null"
    @create="createPartnerContact"
    @selected="addContact" />

  <PartnerContactCreateModal
    v-if="showPartnerContactModal"
    :model-value="{ name: newPartnerContactName }"
    :model-id="groupId"
    model="Group"
    :can-add-company="false"
    @closed="showPartnerContactModal = false"
    @created="addContact($event)" />

  <AddPartnersConnectedToPartnerModal
    v-if="selectedCompany"
    :invite="invite"
    :contact="null"
    :company="selectedCompany"
    :event-name="invite.event.name"
    :existing-contacts="partnerContacts"
    :existing-companies="partnerCompanies"
    @closed="selectedCompany = null"
    @contact-added="partnerContacts.push($event)"
    @company-added="partnerCompanies.push($event)" />

  <ConnectPartnerCompanyToGroupModal
    v-if="connectPartnerCompanyToGroupCompany"
    :partner-company="connectPartnerCompanyToGroupCompany"
    @closed="checkIfAnyContactsShouldBeInvited(connectPartnerCompanyToGroupCompany)"
    @connected="partnerCompanyConnectedToGroup" />
</template>
